/* eslint-disable no-async-promise-executor */
import { isBefore } from 'date-fns';

import { BehaviorSubject } from 'rxjs';

import { StorageService } from '../storage/storage.service';
import { inject, Injectable } from '@angular/core';

import { EMPTY_STRING } from 'bp-framework/dist/common/common.const';
import { STORAGE_KEYS } from 'bp-framework/dist/storage/storage.const';

import { IUserDetails } from 'bp-framework/dist/user/user.interface';
import { mergeWith } from 'bp-framework/dist/common/common.utils';
import { PROJECT_ENV_CONFIG_TOKEN } from '../../configuration/configuration.const';
import { I18nService } from '../i18n/i18n.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // TODO: IMPORTANT: This service is not used inside the 'bp-player-frontend' project. Revisit it and decide will each project have its own AuthService or will we have some sort of abstract AuthService that will be used in all projects
  private projectConfig: any = inject<any>(PROJECT_ENV_CONFIG_TOKEN);
  private i18nService: I18nService = inject(I18nService);

  public user: Partial<IUserDetails> | null = null;

  public user$: BehaviorSubject<Partial<IUserDetails> | null> = new BehaviorSubject<Partial<IUserDetails> | null>(null);

  public isLoggedIn = false;
  public isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public rawToken$: BehaviorSubject<string> = new BehaviorSubject<string>(EMPTY_STRING);

  constructor(private storageService: StorageService) {}

  /**
   * @async
   * @function userDetailsChanged
   * @description Takes the data received by param and updates the user related observables.
   * @param {Partial<IUserDetails> | null} value - Object containing user details
   * @returns {Promise<void>}
   */
  public async userDetailsChanged(data: Partial<IUserDetails> | null): Promise<Partial<IUserDetails> | null> {
    this.user = data === null ? null : mergeWith(this.user, data);

    this.user$.next(this.user);

    if (this.user?.auth?.token) {
      this.adaptApplicationToUser(this.user);
      await this.storageService.setLocalItem(STORAGE_KEYS.userDetails, this.user);
    } else {
      await this.storageService.removeLocalItem(STORAGE_KEYS.userDetails);

      if (!this.projectConfig?.features?.storeCredentials) {
        await this.storageService.removeLocalItem(STORAGE_KEYS.userCredentials);
      }
    }

    return this.user$.value;
  }

  public async userAuthChanged(data?: Partial<IUserDetails> | null): Promise<Partial<IUserDetails> | null> {
    this.rawToken$.next(data?.auth?.token ?? EMPTY_STRING);

    this.isLoggedIn = !!data?.auth?.token;
    this.isLoggedIn$.next(this.isLoggedIn);

    this.user = data?.auth?.token ? mergeWith(this.user, data) : null;

    return await this.userDetailsChanged(this.user);
  }

  /**
   * @async
   * @function handleAppInitialization
   * @description Used during the app initializaiton. Covers the following scenarios:
   * 1. If the token exists in the local storage, then the user did not logout (manually clicked on the Log Out button).
   *    In this case we can assume that if the token is valid, we don't need ask user to login
   * 2. If the token doesn't exist, then the user must have clicked on the Log Out button (or removed data from local storage).
   *    Inside the 'userDetailsChanged' function, we remove token from the storage when the user logs out. Because of that, we will assume that since there is no token, user should be navigated to the login page
   * @param {void}
   * @returns {Promise<void>}
   */
  public async handleAppInitialization(): Promise<Partial<IUserDetails> | null> {
    const userDetails: Partial<IUserDetails> | null = await this.storageService.getLocalItem(STORAGE_KEYS.userDetails);
    return this.hasTheUserTokenTimeExpired(userDetails) ? this.logout() : this.userAuthChanged(userDetails);
  }

  /**
   * @function isTokenExpired
   * @description Checks if the token is expired. Returns true if expired, false otherwise.
   * @returns {Promise<boolean>}
   */
  public isTokenExpired(): boolean {
    try {
      const userDetails: Partial<IUserDetails> | null = this.user$?.value;

      return this.hasTheUserTokenTimeExpired(userDetails);
    } catch (error) {
      return true; // Consider token as expired if an error occurs
    }
  }

  private hasTheUserTokenTimeExpired(userDetails: Partial<IUserDetails> | null): boolean {
    // If userDetails, auth, token, or tokenExp is missing, consider token as expired
    if (!userDetails?.auth?.token || typeof userDetails?.auth?.tokenExpDateIso !== 'string') {
      return true;
    }

    // Extract token expiration time from userDetails
    const tokenExpTimestamp: number = new Date(userDetails.auth.tokenExpDateIso).getTime();
    // Check if the token expiration time is in seconds or milliseconds
    const isTimestampInSeconds: boolean = tokenExpTimestamp <= 2147483647;
    // Convert token expiration time to Date object
    const tokenDate: Date = isTimestampInSeconds ? new Date(tokenExpTimestamp * 1000) : new Date(tokenExpTimestamp);

    // Compare token expiration time with current time
    return isBefore(tokenDate, new Date());
  }

  public async logout(): Promise<Partial<IUserDetails> | null> {
    return this.userAuthChanged(null);
  }

  private adaptApplicationToUser(user: Partial<IUserDetails> | null): void {
    const selectedLanguage: string | undefined = user?.attributes?.preferredLang;

    if (typeof selectedLanguage !== 'string' || selectedLanguage === '') {
      return;
    }

    if (selectedLanguage && selectedLanguage !== this.i18nService?.selectedLanguage?.value) {
      this.i18nService.changeLanguage(selectedLanguage);
    }
  }
  //#endregion utils
}
