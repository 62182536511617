import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { DIALOG_DISMISS_ROLES } from 'bp-framework/dist/dialogs/dialogs.const';
import { I18nService } from '../../services/i18n/i18n.service';
import { IListItem } from 'bp-framework/dist/common/common.interface';
import { FlagImgComponent } from '../flag-img/flag-img.component';
import { getCountryAlpha2CodeByLanguageCode } from 'bp-framework/dist/i18n/i18n.utils';

@Component({
  selector: 'lib-language-options-dialog',
  standalone: true,
  imports: [CommonModule, IonicModule, TranslateModule, FlagImgComponent],
  templateUrl: './language-options-dialog.component.html',
  styleUrl: './language-options-dialog.component.scss'
})
export class LanguageOptionsComponent {
  private modalController: ModalController = inject(ModalController);
  public i18nService: I18nService = inject(I18nService);

  @Input() showHeader!: boolean;

  public selectedLangOption: Partial<IListItem<string>> = this.i18nService.selectedLanguage;

  public convertLangAlpha2ToCountryAlpha2Code(langAlpha2Code: string | undefined): string {
    return langAlpha2Code ? getCountryAlpha2CodeByLanguageCode(langAlpha2Code) : '';
  }

  public handleClose(): void {
    this.modalController.dismiss('hello theres', 'cancel');
  }

  public handleConfirm(): void {
    this.changeLanguage(this.selectedLangOption);
    this.modalController.dismiss(null, DIALOG_DISMISS_ROLES.confirm);
  }

  compareWith(option1: Partial<IListItem<string>>, option2: Partial<IListItem<string>>) {
    return option1.value === option2.value;
  }

  handleChange(event: Event) {
    this.selectedLangOption = (event as CustomEvent)?.detail?.value;

    if (this.showHeader) {
      return;
    }

    this.changeLanguage(this.selectedLangOption);
  }

  trackItems(index: number, item: Partial<IListItem<string>>) {
    return item.value;
  }

  private changeLanguage(languageOption: Partial<IListItem<string>> | null): void {
    if (!languageOption || typeof languageOption?.value !== 'string') {
      return;
    }

    this.i18nService.changeLanguage(languageOption.value);
    this.i18nService.saveNewlySelectedLanguageToUserProfile$.next(languageOption.value);
    setTimeout(() => {
      window.location.reload();
    }, 300);
  }
}
