export class ProjectEnvConfig {
  private _userPersona: string;

  constructor(initialPersona: string) {
    this._userPersona = initialPersona;
  }

  get userPersona(): string {
    return this._userPersona;
  }

  set userPersona(newValue: string) {
    this._userPersona = newValue;
  }
}
