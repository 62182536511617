import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { KeyValueMap } from 'bp-framework/dist/common/common.interface';

export interface IApiRequestConfig {
  params?: {
    [key: string]: any;
  };
  body?: {
    [key: string]: any;
  };
  headers?: {
    [key: string]: any;
  };
}

//#region Abstract Logic
import { Observable, catchError, take, throwError, timeout } from 'rxjs';

export const DEFAULT_TIMEOUT_BEFORE_REQUEST_IS_CONSIDERED_FAILED = 10000;

export const assembleRequestInAbstractWay = <T>(
  httpClient: HttpClient,
  httpMethod: 'GET' | 'PUT' | 'POST' | 'PATCH' | 'DELETE',
  url: string,
  config?: IApiRequestConfig
): Observable<T | null> => {
  let headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  if (config?.headers) {
    headers = addHeaders(headers, config.headers);
  }

  const params = new HttpParams({ fromObject: filterPropertiesThatAreUndefined(config?.params) });

  const body: any = config?.body;

  return httpClient.request<T>(httpMethod, url, { headers, params, body }).pipe(
    take(1),
    timeout(DEFAULT_TIMEOUT_BEFORE_REQUEST_IS_CONSIDERED_FAILED),
    catchError((error: Error) => {
      // TODO: In here, we can add some global Error handler that will take the `error` object and perform some custom actions
      // or, if that would be better choice, we can add global ErrorHandler
      return throwError(() => error); // Right now, we will return null in case of errors. The reason for this is because we want to avoid having too many try/catch statements in our code
    })
  );
};

export const addHeaders = (baseHeaders: HttpHeaders, additionalHeaders: { [header: string]: string }): HttpHeaders => {
  let mergedHeaders = baseHeaders;

  for (const header in additionalHeaders) {
    if (Object.hasOwnProperty.call(additionalHeaders, header)) {
      mergedHeaders = mergedHeaders.append(header, additionalHeaders[header]);
    }
  }

  return mergedHeaders;
};

export const createObjectWithValues = (...args: Array<[string, any]>): KeyValueMap => {
  const obj: KeyValueMap = {};

  for (const [key, value] of args) {
    if (value !== undefined && value !== null && value !== '') {
      obj[key] = value;
    }
  }

  return obj;
};

export const filterPropertiesThatAreUndefined = (params?: { [param: string]: any }): { [param: string]: any } => {
  const filteredParams: { [param: string]: any } = {};

  if (params) {
    for (const key in params) {
      if (Object.prototype.hasOwnProperty.call(params, key) && params[key] !== undefined) {
        if (Array.isArray(params[key])) {
          params[key].forEach((value: any) => {
            filteredParams[key] = filteredParams[key] ? [...filteredParams[key], value] : [value];
          });
        } else {
          filteredParams[key] = params[key];
        }
      }
    }
  }

  return filteredParams;
};
//#endregion Abstract Logic
