import { animate, query, stagger, state, style, transition, trigger } from '@angular/animations';

export const OUTLET_ANIMATION = trigger('outletAnimation', [
  transition('* => *', [query(':enter', [style({ opacity: 0 }), stagger(100, [animate('0.3s', style({ opacity: 1 }))])], { optional: true })])
]);

export const ENTER_ANIMATION = trigger('enterAnimation', [
  transition('* => *', [query(':enter', [style({ opacity: 0 }), stagger(100, [animate('0.3s', style({ opacity: 1 }))])], { optional: true })])
]);

export const FADE_IN_ANIMATION = trigger('fadeIn', [state('in', style({ opacity: 1 })), transition(':enter', [style({ opacity: 0 }), animate(600)])]);

export const LIST_ANIMATION = trigger('listAnimation', [
  transition('* => *', [query(':enter', [style({ opacity: 0 }), stagger(100, [animate('0.3s', style({ opacity: 1 }))])], { optional: true })])
]);
