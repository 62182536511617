import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-flag-img',
  standalone: true,
  imports: [],
  templateUrl: './flag-img.component.html',
  styleUrl: './flag-img.component.scss'
})
export class FlagImgComponent {
  @Input() set countryIsoAlpha2Code(value: string) {
    this.flagIsoCode = value;
    this.flagSrc = this.assembleFlagSrc(this.flagIsoCode);
  }

  public flagIsoCode = '';
  public flagSrc = '';

  private assembleFlagSrc(isoCode: string): string {
    return isoCode ? `assets/flags/${isoCode.toLowerCase()}.svg` : '';
  }
}
