import { EnvironmentInjector, inject, runInInjectionContext } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot, UrlTree } from '@angular/router';

export const runGuardsInOrder = (...guards: Array<CanActivateFn>) => {
  return async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const injector = inject(EnvironmentInjector);
    let result: any = true;

    for (let i = 0; i < guards.length; i++) {
      // Short-circuit if the previous guard returned false
      if (result === false) break;

      const currentGuard = guards[i];

      // Run the current guard in the injection context
      const currentGuardResult = runInInjectionContext(injector, () => {
        return currentGuard(route, state);
      });

      // Update our result
      result = await currentGuardResult;

      if (result instanceof UrlTree) return result;
    }

    return result;
  };
};
