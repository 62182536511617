import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const confirmValuesMatchingValidator = (passwordFieldName: string, confirmPasswordFieldName: string): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const firstValue = control.get(passwordFieldName)?.value;
    const secondValue = control.get(confirmPasswordFieldName)?.value;
    // TODO: Standardize error messages wo we an create a shared error message component that will understand what is wrong and what message to display
    return firstValue === secondValue ? null : { PasswordNoMatch: true };
  };
};
