import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, Subject, from, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { PROJECT_ENV_CONFIG_TOKEN } from '../../../shared/configuration/configuration.const';
import { SurfpointAuthService } from '../services/auth/surfpoint-auth.service';
import { IEnvApiBase, IEnvConfig } from 'bp-framework/dist/configuration/configuration.interface';

@Injectable()
export class SurfpointAuthInterceptor implements HttpInterceptor {
  private projectConfig: IEnvConfig<IEnvApiBase> = inject<IEnvConfig<IEnvApiBase>>(PROJECT_ENV_CONFIG_TOKEN);

  private acceptableUrls: string[] = this.projectConfig?.httpInterceptors?.attachAuthTokenToTheseUrls;
  private unacceptableUrls: string[] = this.projectConfig?.httpInterceptors?.doNotAttachAuthTokenToTheseUrls;

  private isRefreshing = false;
  private refreshTokenSubject$: Subject<any> = new Subject<any>();

  constructor(private authService: SurfpointAuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isThisACallToAcceptableUrl: boolean = this.acceptableUrls.some((url: string): boolean => request.url.startsWith(url));

    if (!isThisACallToAcceptableUrl) {
      // Ukoliko poziv nije upucen nasem APIu, onda ne treba da radimo nista
      return next.handle(request);
    }

    const isThisACallToUrlWeWantToSkip: boolean = this.unacceptableUrls.some((url: string): boolean => request.url.startsWith(url));

    if (isThisACallToUrlWeWantToSkip) {
      // Ako je poziv upucen API endpointu koji treba da uloguje korisnika, onda tu nije potreban token u headeru
      return next.handle(request);
    }

    if (request.headers.has('Authorization')) {
      // Ako vec postoji header, onda nije potrebno nista da dodajemo
      return next.handle(request);
    }

    if (this.authService.rawToken$.value) {
      // U slucaju da token postoji, onda mozemo da ga dodamo u header
      request = request.clone({
        setHeaders: {
          Authorization: `token ${this.authService.rawToken$.value}`
        }
      });
    }

    return next.handle(request).pipe(
      catchError((errorResponse: HttpErrorResponse): Observable<any> => {
        return this.handle401Error(errorResponse, request, next);
      })
    );
  }

  /**
   * @function handle401Error
   * @description Sadrzi logiku koja ce ukoliko uslovi dozvoljavaju, pokusati da ponovo uloguje korisnika ako je greska vezana za "401 Unauthorized"
   * @param {HttpErrorResponse} errorResponse - Greska koja je dobijena iz prvog poziva koji je neuspesno zavrsen
   * @param {HttpRequest} request - HTTP zahtev koji se salje
   * @param {HttpHandler} next - HTTP handler za poziv koji cemo modifikovati i ponovo poslati
   * @returns {Observable<any>}
   */
  private handle401Error(errorResponse: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    // Pozivi koji nisu vezani za "Unauthorized", ne treba da se ponavljaju
    if (errorResponse?.status !== 401) {
      return throwError(() => errorResponse);
    }

    // Ako je poziv upucen API endpointu koji treba da uloguje korisnika, onda ne treba da se ponavlja (zelimo da izbegnemo loop)
    const isThisACallToUrlWeWantToSkip: boolean = this.unacceptableUrls.some(
      (url: string): boolean => typeof errorResponse?.url === 'string' && errorResponse?.url?.startsWith(url)
    );
    if (isThisACallToUrlWeWantToSkip) {
      return throwError(() => errorResponse);
    }

    if (!this.isRefreshing) {
      // Ukoliko proces osvezavanja tokena nije zapocet, onda mozemo da krenemo u taj proces. Ukoliko jeste, ovaj deo koda se preskace

      this.isRefreshing = true;
      this.refreshTokenSubject$.next(null);

      // Prvo cemo pokusati da ulogujemo korisnika sa kredencijalima koji se cuvaju u localstorage i nakon toga bi trebalo da dobijemo novi token
      return from(this.authService.tryLoginWithExistingCredentials('player')).pipe(
        switchMap(() => {
          // Ako uspesno dobijemo novi token, onda mozemo da ga dodamo u header poziva koji je prethodno neuspesno zavrsen
          this.isRefreshing = false;
          this.refreshTokenSubject$.next(this.authService.rawToken$.value);

          return next.handle(this.addTokenHeader(request, this.authService.rawToken$.value));
        }),
        catchError(error => {
          // U slucaju greske, ili bezuspesnog dobijanja novog tokena, vraticemo gresku
          this.isRefreshing = false;

          // TODO: Check if we should logout user in the case of any error or just the ones with the 403?

          return throwError(() => error);
        })
      );
    } else {
      // Ako je proces osvezavanja tokena odradjen, onda mozemo da novi token dodamo u header poziva koji je prethodno neuspesno zavrsen
      return this.refreshTokenSubject$.pipe(
        filter(token => token !== null),
        take(1),
        switchMap(token => next.handle(this.addTokenHeader(request, token)))
      );
    }
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({
      headers: request.headers.set('Authorization', `token ${token}`)
    });
  }
}
