import { Injectable, inject } from '@angular/core';
import { assembleRequestInAbstractWay } from '../../../../shared/services/api/api.service.utils';
import { HttpClient } from '@angular/common/http';
import { IProfile } from 'bp-framework/dist/env-specific/1x2team/user/user.interface';
import {
  ICashupCreateResponse,
  ICashupDetails,
  I1x2TeamCasinoGameDetails,
  ICasinoGamePlay,
  ICasinoJackpot,
  IInOutReports,
  IPayloadResult,
  IPayloadResults,
  IPlayerActiveResponse,
  ITransaction
} from 'bp-framework/dist/env-specific/1x2team/casino/surfpoint.interface';
import { format } from 'date-fns';
import { firstValueFrom } from 'rxjs';
import { IEnvConfig } from 'bp-framework/dist/configuration/configuration.interface';
import { ICreditAddOrDepositResponse } from 'bp-framework/dist/env-specific/1x2team/transactions/transactions.interface';
import { IEnvApiOn1x2Team } from 'bp-framework/dist/env-specific/1x2team/configuration/configuration.interface';
import { PROJECT_ENV_CONFIG_TOKEN } from '../../../../shared/configuration/configuration.const';

@Injectable({
  providedIn: 'root'
})
export class SurfpointApi1x2TeamService {
  private projectConfig: IEnvConfig<IEnvApiOn1x2Team> = inject<IEnvConfig<IEnvApiOn1x2Team>>(PROJECT_ENV_CONFIG_TOKEN);
  private officeBaseUrl = this.projectConfig?.api?.baseUrls?.office;
  private casinoBaseUrl = this.projectConfig?.api?.baseUrls?.casino;

  constructor(private httpClient: HttpClient) {}

  public getAvailableCasinoGames(): Promise<IPayloadResults<I1x2TeamCasinoGameDetails> | null> {
    const fullPath = `${this.casinoBaseUrl}/surfpoint`;
    return firstValueFrom(assembleRequestInAbstractWay<IPayloadResults<I1x2TeamCasinoGameDetails>>(this.httpClient, 'GET', fullPath));
  }

  public getCasinoGamePlayUrl(gameId: number): Promise<ICasinoGamePlay | null> {
    const fullPath = `${this.casinoBaseUrl}/gaming/api/create/${gameId}/0`;
    return firstValueFrom(
      assembleRequestInAbstractWay<ICasinoGamePlay>(this.httpClient, 'POST', fullPath, {
        body: { gameId }
      })
    );
  }

  public getCurrentJackpot(agentUsername: string): Promise<ICasinoJackpot | null> {
    const fullPath = `${this.casinoBaseUrl}/surfpoint/jackpot?username=${agentUsername}`;
    return firstValueFrom(assembleRequestInAbstractWay<ICasinoJackpot>(this.httpClient, 'GET', fullPath));
  }

  public checkIfPlayerIsActive(userId: number): Promise<IPlayerActiveResponse | null> {
    const fullPath = `${this.casinoBaseUrl}/surfpoint/check_player/${userId}`;
    return firstValueFrom(assembleRequestInAbstractWay<IPlayerActiveResponse>(this.httpClient, 'GET', fullPath));
  }

  public getListOfPlayersUnderAgent(): Promise<IPayloadResults<IProfile> | null> {
    const fullPath = `${this.officeBaseUrl}/clients/players?page=1`;
    return firstValueFrom(assembleRequestInAbstractWay<IPayloadResults<IProfile>>(this.httpClient, 'GET', fullPath));
  }

  public getTransationsInDateRange(startDate: Date, endDate: Date, categoryType: 'money', page = 1): Promise<IPayloadResults<ITransaction> | null> {
    const startDateFormatted: string = format(startDate, 'yyyy-MM-dd');
    const endDateFormatted: string = format(endDate, 'yyyy-MM-dd');
    const fullPath = `${this.officeBaseUrl}/clients/transactions?date_range_after=${startDateFormatted}&date_range_before=${endDateFormatted}&category_type='${categoryType}'&page=${page}`;
    return firstValueFrom(assembleRequestInAbstractWay<IPayloadResults<ITransaction>>(this.httpClient, 'GET', fullPath));
  }

  public getInOutReportsInDateRange(startDate: Date, endDate: Date): Promise<IPayloadResult<IInOutReports> | null> {
    const startDateFormatted: string = format(startDate, 'yyyy-MM-dd');
    const endDateFormatted: string = format(endDate, 'yyyy-MM-dd');
    const fullPath = `${this.officeBaseUrl}/surfpoint/reports/casino_reports?start_date=${startDateFormatted}&end_date=${endDateFormatted}`;
    return firstValueFrom(assembleRequestInAbstractWay<IPayloadResult<IInOutReports>>(this.httpClient, 'GET', fullPath));
  }

  public getCashups(agentUsername: string): Promise<IPayloadResults<ICashupDetails> | null> {
    const fullPath = `${this.officeBaseUrl}/surfpoint/cashup?user=${agentUsername}`;
    return firstValueFrom(assembleRequestInAbstractWay<IPayloadResults<ICashupDetails>>(this.httpClient, 'GET', fullPath));
  }

  public createCashup(): Promise<ICashupCreateResponse | null> {
    const fullPath = `${this.officeBaseUrl}/surfpoint/cashup`;
    return firstValueFrom(
      assembleRequestInAbstractWay<ICashupCreateResponse>(this.httpClient, 'POST', fullPath, {
        body: {}
      })
    );
  }

  public addCredits(userId: number, amount: number): Promise<ICreditAddOrDepositResponse | null> {
    const fullPath = `${this.officeBaseUrl}/clients/player/${userId}/deposit/`;
    return firstValueFrom(
      assembleRequestInAbstractWay<ICreditAddOrDepositResponse>(this.httpClient, 'POST', fullPath, {
        body: { amount }
      })
    );
  }

  public withdrawCredits(userId: number, amount: number): Promise<ICreditAddOrDepositResponse | null> {
    const fullPath = `${this.officeBaseUrl}/clients/player/${userId}/withdraw/`;
    return firstValueFrom(
      assembleRequestInAbstractWay<ICreditAddOrDepositResponse>(this.httpClient, 'POST', fullPath, {
        body: { amount }
      })
    );
  }

  public blockPlayer(playerId: number, playerAuthToken: string): Promise<unknown> {
    const fullPath = `${this.officeBaseUrl}/surfpoint/send_action`;
    return firstValueFrom(
      assembleRequestInAbstractWay<unknown>(this.httpClient, 'POST', fullPath, {
        body: { action: 'panic', token: playerAuthToken, user_id: playerId }
      })
    );
  }

  public isPlayerActive(playerId?: number): Promise<unknown> {
    const fullPath = `${this.casinoBaseUrl}/surfpoint/check_player/${playerId}`;
    return firstValueFrom(
      assembleRequestInAbstractWay<unknown>(this.httpClient, 'GET', fullPath, {
        body: {}
      })
    );
  }
}
