<form *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="handleLogin()">
  <ion-list>
    <ion-item lines="none" class="custom-item">
      <ion-input type="text" inputmode="text" [placeholder]="'commons.username' | translate" formControlName="username"></ion-input>
    </ion-item>
    <ion-item class="password-item" lines="none" class="custom-item">
      <ion-input [type]="passwordInputMode" inputmode="text" [placeholder]="'commons.password' | translate" formControlName="password"> </ion-input>
      <ion-button fill="clear" slot="end" [attr.aria-label]="'commons.showHide' | translate" (click)="togglePasswordInputMode()">
        <span slot="icon-only" aria-hidden="true" class="ph" [ngClass]="passwordInputMode === 'text' ? 'ph-eye-slash' : 'ph-eye'"></span>
      </ion-button>
    </ion-item>
  </ion-list>
  <ion-button type="submit" [color]="submitBtnColor" [disabled]="formGroup.invalid" expand="block" class="ion-margin-top"
    ><ng-content select="[submitButtonContent]"></ng-content
  ></ion-button>
</form>
