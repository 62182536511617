/* eslint-disable no-async-promise-executor */
import {
  ActionSheetButton,
  ActionSheetController,
  ActionSheetOptions,
  AlertController,
  AlertOptions,
  LoadingController,
  LoadingOptions,
  ModalController,
  ModalOptions,
  PopoverController,
  PopoverOptions,
  ToastController,
  ToastOptions
} from '@ionic/angular';

import { Injectable } from '@angular/core';

import { OverlayEventDetail } from '@ionic/core';
import { EMPTY_STRING } from 'bp-framework/dist/common/common.const';
import { DIALOG_DISMISS_ROLES } from 'bp-framework/dist/dialogs/dialogs.const';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class DialogsService {
  constructor(
    private loadingController: LoadingController,
    private actionSheetController: ActionSheetController,
    private modalController: ModalController,
    private toastController: ToastController,
    private alertController: AlertController,
    private popoverController: PopoverController,
    private translateService: TranslateService
  ) {}

  //#region Modal
  public async presentModal(options: ModalOptions): Promise<OverlayEventDetail<any>> {
    const dialog: HTMLIonModalElement = await this.modalController.create(options);
    await dialog.present();
    return dialog.onWillDismiss();
  }

  public async dismissModal(): Promise<boolean> {
    const top: HTMLIonModalElement | undefined = await this.modalController.getTop();

    if (!top) {
      return Promise.reject();
    }

    return top.dismiss();
  }
  //#endregion Modal

  //#region Loading
  public async presentLoading(options?: LoadingOptions): Promise<void> {
    const loading: HTMLIonLoadingElement = await this.loadingController.create({
      spinner: options?.spinner || 'lines-small',
      message: options?.message || this.translateService.instant('commons.loading'),
      cssClass: options?.cssClass || EMPTY_STRING,
      showBackdrop: options?.showBackdrop,
      duration: options?.duration || 0, // 0 means that it will run forever
      translucent: options?.translucent,
      animated: options?.animated,
      backdropDismiss: options?.backdropDismiss,
      keyboardClose: options?.keyboardClose,
      id: options?.id || EMPTY_STRING
    });
    return loading.present();
  }

  public async dismissLoading(): Promise<boolean> {
    const top: HTMLIonLoadingElement | undefined = await this.loadingController.getTop();

    if (!top) {
      return Promise.reject();
    }

    return top.dismiss();
  }
  //#endregion Loading

  //#region Action Sheet
  public async presentActionSheet(options: ActionSheetOptions): Promise<OverlayEventDetail<any>> {
    const tmpOptions: ActionSheetOptions = {
      header: options?.header || this.translateService.instant('commons.options'),
      subHeader: options?.subHeader || EMPTY_STRING,
      cssClass: options?.cssClass || EMPTY_STRING,
      buttons: options?.buttons || [],
      mode: 'md'
    };

    const cancelBtn: ActionSheetButton = {
      icon: 'close',
      text: this.translateService.instant('commons.close'),
      role: DIALOG_DISMISS_ROLES.cancel
    };
    tmpOptions.buttons.push(cancelBtn);

    const actionSheet: HTMLIonActionSheetElement = await this.actionSheetController.create(tmpOptions);

    await actionSheet.present();
    return actionSheet.onDidDismiss();
  }

  public async dismissActionSheet(): Promise<boolean> {
    const actionSheet: HTMLIonActionSheetElement | undefined = await this.actionSheetController.getTop();
    if (actionSheet) {
      return actionSheet.dismiss();
    } else {
      return Promise.resolve(false);
    }
  }
  //#endregion Action Sheet

  //#region Toast
  public async presentToast(options: ToastOptions): Promise<OverlayEventDetail<any>> {
    const tmpOptions: ToastOptions = {
      duration: 5000,
      buttons: options?.buttons || [{ text: this.translateService.instant('commons.ok'), role: DIALOG_DISMISS_ROLES.cancel }],
      position: options?.position || 'top',
      color: options?.color || 'primary',
      message: options?.message || ''
    };

    const toast: HTMLIonToastElement = await this.toastController.create(tmpOptions);

    await toast.present();
    return toast.onWillDismiss();
  }
  //#endregion Toast

  //#region Alert
  public async presentAlert(options?: AlertOptions): Promise<OverlayEventDetail<any>> {
    const alert: HTMLIonAlertElement = await this.alertController.create({
      header: options?.header || EMPTY_STRING,
      subHeader: options?.subHeader || EMPTY_STRING,
      message: options?.message || EMPTY_STRING,
      inputs: options?.inputs || [],
      buttons: options?.buttons || [{ text: this.translateService.instant('commons.ok') }]
    });
    await alert.present();
    return alert.onDidDismiss();
  }
  //#endregion Alert

  //#region Alert - Confirmation
  // TODO: Use object instead of individual parameters
  public async askUserToConfirmHisActions(options?: AlertOptions, typeOfConfirmationLabels: 'yes-no' | 'confirm-cancel' | 'ok' = 'confirm-cancel'): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      let response = false;

      const cancelBtnTranslationKey: string =
        typeOfConfirmationLabels === 'yes-no'
          ? 'commons.no'
          : typeOfConfirmationLabels === 'confirm-cancel'
            ? 'commons.cancel'
            : typeOfConfirmationLabels === 'ok'
              ? 'commons.ok'
              : '';

      const confirmBtnTranslationKey: string =
        typeOfConfirmationLabels === 'yes-no'
          ? 'commons.yes'
          : typeOfConfirmationLabels === 'confirm-cancel'
            ? 'commons.confirm'
            : typeOfConfirmationLabels === 'ok'
              ? 'commons.ok'
              : '';

      const tmpDefaultTitle = this.translateService.instant('dialogs.confirmation.title');
      const tmpDefaultMessage = this.translateService.instant('dialogs.confirmation.message');

      const alertOptions: AlertOptions = {
        header: options?.header || tmpDefaultTitle,
        message: options?.message || tmpDefaultMessage,
        buttons: [
          {
            text: this.translateService.instant(confirmBtnTranslationKey),
            handler: () => {
              response = true;
            }
          }
        ]
      };

      if (alertOptions?.buttons && typeOfConfirmationLabels !== 'ok') {
        alertOptions.buttons.unshift({
          text: this.translateService.instant(cancelBtnTranslationKey),
          role: 'cancel',
          handler: () => {
            response = false;
          }
        });
      }

      await this.presentAlert(alertOptions);
      resolve(response);
    });
  }
  //#endregion Alert - Confirmation

  //#region Popover
  public async presentPopover(options: PopoverOptions): Promise<OverlayEventDetail<any>> {
    const dialog: HTMLIonPopoverElement = await this.popoverController.create(options);
    await dialog.present();
    return dialog.onWillDismiss();
  }

  public async dismissPopover(): Promise<boolean> {
    const top: HTMLIonPopoverElement | undefined = await this.popoverController.getTop();

    if (!top) {
      return Promise.reject();
    }

    return top.dismiss();
  }
  //#endregion Popover
}
