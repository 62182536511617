import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { assembleRequestInAbstractWay, createObjectWithValues } from '../../../../shared/services/api/api.service.utils';

import { FCountryOrCompetition } from 'bp-framework/dist/env-specific/1x2team/feed/competitions/competitions.interface';
import { FFixture, FPayloadGetSingleFixture, FSportMarket } from 'bp-framework/dist/env-specific/1x2team/feed/markets/markets.interface';
import { PayloadFeedRows } from 'bp-framework/dist/env-specific/1x2team/common/common.interface';
import { GameStatus, SportId } from 'bp-framework/dist/sports/sports.enum';
import { FSport } from 'bp-framework/dist/env-specific/1x2team/feed/sports/sports.interface';
import { firstValueFrom } from 'rxjs';
import { IEnvConfig } from 'bp-framework/dist/configuration/configuration.interface';
import { IEnvApiOn1x2Team } from 'bp-framework/dist/env-specific/1x2team/configuration/configuration.interface';
import { PROJECT_ENV_CONFIG_TOKEN } from '../../../../shared/configuration/configuration.const';

@Injectable({
  providedIn: 'root'
})
export class FeedApi1x2TeamService {
  private httpClient: HttpClient = inject(HttpClient);

  private projectConfig: IEnvConfig<IEnvApiOn1x2Team> = inject<IEnvConfig<IEnvApiOn1x2Team>>(PROJECT_ENV_CONFIG_TOKEN);
  private feedBaseUrl = this.projectConfig?.api?.baseUrls?.feed;

  //#region GENERAL
  /**
   * https://webfeed.zerosoft.bet/docs#/General/get_countries_general_countries_get
   * @param {string} sort - Property which to use to sort the results
   * @param {number} limit - Max number of results to return
   * @param {number} offset - Start position of the search. Something like cursor
   * @param {string} search -
   * @returns - List of countries that are registered on the Feed
   */
  public getAllCountriesOrCompetitionsFromFeed(sort = 'code', limit?: number, offset?: number, search?: string): Promise<PayloadFeedRows<FCountryOrCompetition> | null> {
    const fullPath = `${this.feedBaseUrl}/general/countries`;
    return firstValueFrom(
      assembleRequestInAbstractWay<PayloadFeedRows<FCountryOrCompetition>>(this.httpClient, 'GET', fullPath, {
        params: createObjectWithValues(['sort', sort], ['limit', limit], ['offset', offset], ['search', search])
      })
    );
  }
  //#endregion GENERAL

  //#region SPORTS
  /**
   * https://webfeed.zerosoft.bet/docs#/Sports/get_sports_sports_all_get
   * @param {number} sport_id - ID of the selected sport
   * @param {number} limit - Max number of results to return
   * @param {number} offset - Start position of the search. Something like cursor
   * @returns - List of sports that are available on the Feed
   */
  public getAllSportsOnTheFeed(sport_id: SportId = SportId.AllSports, limit?: number, offset?: number): Promise<PayloadFeedRows<FSport> | null> {
    const fullPath = `${this.feedBaseUrl}/sports/all`;
    return firstValueFrom(
      assembleRequestInAbstractWay<PayloadFeedRows<FSport>>(this.httpClient, 'GET', fullPath, {
        params: createObjectWithValues(['sport_id', sport_id], ['limit', limit], ['offset', offset])
      })
    );
  }

  /**
   * https://webfeed.zerosoft.bet/docs#/Sports/get_markets_sports_markets_get
   * @param {number} sport_id - Enum of the selected sport (sport id)
   * @param {number} limit - Max number of results to return
   * @param {number} offset - Start position of the search. Something like a cursor
   * @returns - List of all markets for a particular sport
   */
  public getMarketsForASingleSport(sport_id: SportId, limit: number, offset: number): Promise<PayloadFeedRows<FSportMarket> | null> {
    const fullPath = `${this.feedBaseUrl}/sports/markets`;
    return firstValueFrom(
      assembleRequestInAbstractWay<PayloadFeedRows<FSportMarket>>(this.httpClient, 'GET', fullPath, {
        params: createObjectWithValues(['sport_id', sport_id], ['limit', limit], ['offset', offset])
      })
    );
  }
  //#endregion SPORTS

  //#region FIXTURES
  /**
   * https://webfeed.zerosoft.bet/docs#/Fixtures/get_fixtures_fxs_fxs_get
   * @param {number} sport_id - Sport ID
   * @param {string} cc - Country or Competition code. Fixtures can belong to a 'country' or a 'competition' (England, NHL, ATP, Italy, etc)
   * @param {string} sorting - Property by which to sort
   * @param {number} status - Game status (prematch, completed, live, etc)
   * @param {number} limit - Max number of results to return
   * @param {string} text_search - Keyword for search but it will check beginning of the words
   * @param {string} total_providers
   * @returns List of fixtures for a particular sport
   */
  public getFixturesForASingleSport(
    sport_id: SportId,
    limit?: number,
    status?: GameStatus,
    cc?: string,
    sorting?: string,
    text_search?: string,
    total_providers?: string
  ): Promise<PayloadFeedRows<FFixture> | null> {
    const fullPath = `${this.feedBaseUrl}/fxs/fxs`;
    return firstValueFrom(
      assembleRequestInAbstractWay<PayloadFeedRows<FFixture>>(this.httpClient, 'GET', fullPath, {
        params: createObjectWithValues(
          ['sport_id', sport_id],
          ['cc', cc],
          ['sorting', sorting],
          ['status', status],
          ['limit', limit],
          ['text_search', text_search],
          ['total_providers', total_providers]
        )
      })
    );
  }

  /**
   * https://webfeed.zerosoft.bet/docs#/Fixtures/get_single_fx_fxs_single__f_id__get
   * @param {number} fixtureId - ID of the fixture inside the feed database
   * @returns Full details of a specific fixture
   */
  public getSingleFixture(fixtureId: number): Promise<FPayloadGetSingleFixture | null> {
    const fullPath = `${this.feedBaseUrl}/fxs/single/${fixtureId}`;
    return firstValueFrom(assembleRequestInAbstractWay<FPayloadGetSingleFixture>(this.httpClient, 'GET', fullPath, {}));
  }
  //#endregion FIXTURES
}
