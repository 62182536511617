<form *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="handleFormSubmit()">
  <ion-list>
    <ion-item class="password-item">
      <ion-input [type]="oldPasswordInputMode" inputmode="text" [placeholder]="'commons.oldPassword' | translate" formControlName="oldPassword"> </ion-input>
      <ion-button fill="clear" slot="end" [attr.aria-label]="'commons.showHide' | translate" (click)="togglePasswordInputMode('old')">
        <span slot="icon-only" aria-hidden="true" class="ph" [ngClass]="oldPasswordInputMode === 'text' ? 'ph-eye-slash' : 'ph-eye'"></span>
      </ion-button>
    </ion-item>
    <ion-item class="password-item">
      <ion-input [type]="newPasswordInputMode" inputmode="text" [placeholder]="'commons.newPassword' | translate" formControlName="newPassword"> </ion-input>
      <ion-button fill="clear" slot="end" [attr.aria-label]="'commons.showHide' | translate" (click)="togglePasswordInputMode('new')">
        <span slot="icon-only" aria-hidden="true" class="ph" [ngClass]="newPasswordInputMode === 'text' ? 'ph-eye-slash' : 'ph-eye'"></span>
      </ion-button>
    </ion-item>
    <ion-item class="password-item">
      <ion-input [type]="newPasswordConfirmationInputMode" inputmode="text" [placeholder]="'commons.newPasswordConfirmation' | translate" formControlName="newPasswordConfirmation">
      </ion-input>
      <ion-button fill="clear" slot="end" [attr.aria-label]="'commons.showHide' | translate" (click)="togglePasswordInputMode('newConfirmation')">
        <span slot="icon-only" aria-hidden="true" class="ph" [ngClass]="newPasswordConfirmationInputMode === 'text' ? 'ph-eye-slash' : 'ph-eye'"></span>
      </ion-button>
    </ion-item>
  </ion-list>
  <ion-button type="submit" [color]="submitBtnColor" [disabled]="formGroup.invalid" expand="block" class="ion-margin-top"
    ><ng-content select="[submitButtonContent]"></ng-content
  ></ion-button>
</form>
