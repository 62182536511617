import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { PROJECT_ENV_CONFIG_TOKEN } from '../../../../shared/configuration/configuration.const';
import { firstValueFrom } from 'rxjs';
import { assembleRequestInAbstractWay, createObjectWithValues } from '../../../../shared/services/api/api.service.utils';
import { IEnvConfig } from 'bp-framework/dist/configuration/configuration.interface';
import { IEnvApiOn1x2Team } from 'bp-framework/dist/env-specific/1x2team/configuration/configuration.interface';
import { IPayloadResults } from 'bp-framework/dist/env-specific/1x2team/casino/surfpoint.interface';
import { IProfile } from 'bp-framework/dist/env-specific/1x2team/user/user.interface';
import { ICreditAddOrDepositResponse, ITransaction, TransactionApiCallType } from 'bp-framework/dist/env-specific/1x2team/transactions/transactions.interface';
import { IListItem } from 'bp-framework/dist/common/common.interface';
import { Payload } from 'bp-framework/dist/env-specific/1x2team/common/common.interface';
import { format } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class AdminApi1x2TeamService {
  private projectConfig: IEnvConfig<IEnvApiOn1x2Team> = inject<IEnvConfig<IEnvApiOn1x2Team>>(PROJECT_ENV_CONFIG_TOKEN);
  private baseUrl = this.projectConfig?.api?.baseUrls?.office;

  constructor(private httpClient: HttpClient) {}

  public getPlayers(page: number): Promise<IPayloadResults<Partial<IProfile>> | null> {
    const fullPath = `${this.baseUrl}/clients/players`;
    return firstValueFrom(
      assembleRequestInAbstractWay<IPayloadResults<Partial<IProfile>>>(this.httpClient, 'GET', fullPath, {
        params: { page }
      })
    );
  }

  public getPlayer(playerId: string): Promise<Partial<IProfile> | null> {
    const fullPath = `${this.baseUrl}/clients/player/${playerId}`;
    return firstValueFrom(assembleRequestInAbstractWay<Partial<IProfile> | null>(this.httpClient, 'GET', fullPath));
  }

  public makePlayerDeposit(playerId: string, amount: number): Promise<Partial<ICreditAddOrDepositResponse> | null> {
    const fullPath = `${this.baseUrl}/clients/player/${playerId}/deposit/`;
    return firstValueFrom(
      assembleRequestInAbstractWay<Partial<ICreditAddOrDepositResponse> | null>(this.httpClient, 'POST', fullPath, {
        body: { amount }
      })
    );
  }

  public makePlayerWithdrawal(playerId: string, amount: number): Promise<Partial<ICreditAddOrDepositResponse> | null> {
    const fullPath = `${this.baseUrl}/clients/player/${playerId}/withdraw/`;
    return firstValueFrom(
      assembleRequestInAbstractWay<Partial<ICreditAddOrDepositResponse> | null>(this.httpClient, 'POST', fullPath, {
        body: { amount }
      })
    );
  }

  public getTransactionCategoryOptions(): Promise<Partial<IListItem<TransactionApiCallType>>[]> {
    const options: Partial<IListItem<TransactionApiCallType>>[] = [
      {
        label: 'All',
        labelT9nKey: 'all',
        value: 'all',
        sortOrder: 0
      },
      {
        label: 'Wallet',
        labelT9nKey: 'wallet',
        value: 'money',
        sortOrder: 1
      }
    ];
    return Promise.resolve(options);
  }

  public getPlayerTransactions(userId: string, categoryType: TransactionApiCallType, startDate: Date, endDate: Date): Promise<Payload<ITransaction> | null> {
    // https://office.1x2team.com/clients/transactions?category_type=all&date_range_after=2024-04-01&date_range_before=2024-06-02&user_id=46&_=1719127782050 -- single player
    // https://office.1x2team.com/clients/transactions?date_range_after=2024-04-01&date_range_before=2024-06-02&page=1&category_type=money&_=1719127895404 -- group of players

    // https://office.1x2team.com/clients/transactions?category_type=all&date_range_after=2024-04-01&date_range_before=2024-06-02&page=1&user_id=46&_=1719127895404
    const startDateFormatted: string = format(startDate, 'yyyy-MM-dd');
    const endDateFormatted: string = format(endDate, 'yyyy-MM-dd');

    const fullPath = `${this.baseUrl}/clients/transactions`;
    return firstValueFrom(
      assembleRequestInAbstractWay<Payload<ITransaction> | null>(this.httpClient, 'GET', fullPath, {
        params: createObjectWithValues(['user_id', userId], ['category_type', categoryType], ['date_range_after', startDateFormatted], ['date_range_before', endDateFormatted])
      })
    );
  }
}
