<div class="picker-btn-wrapper">
  <span aria-hidden="true" class="ph ph-calendar-dots"></span>
  <!-- First -->
  <ion-button id="start-date-modal" fill="outline" shape="round" size="x-small" color="cta-tertiary">{{ firstDate | date }}</ion-button>
  <!-- Second -->
  <ion-button *ngIf="showSecondDate" id="end-date-modal" fill="outline" shape="round" size="x-small" color="cta-tertiary">{{ secondDate | date }}</ion-button>
</div>

<!-- Start Date Modal -->
<ion-modal [keepContentsMounted]="true" trigger="start-date-modal">
  <ng-template>
    <ion-datetime
      id="cid-start-datetime-modal-instance"
      [presentation]="presentationType"
      [showDefaultTitle]="showDefaultTitle"
      [showDefaultButtons]="showDefaultButtons"
      [preferWheel]="preferWheel"
      [(ngModel)]="firstDate"
      [min]="firstDateMin"
      [max]="firstDateMax"
      [locale]="localeCode"
      [firstDayOfWeek]="firstDayOfWeek"
      [doneText]="doneTextLabel"
      [formatOptions]="{
        date: {
          weekday: 'short',
          month: 'long',
          day: '2-digit'
        }
      }"
      (ionChange)="handleStartDateChange($event)">
      <span slot="title">{{ firstDateModalTitle }}</span>
      <span slot="time-label" *ngIf="showTimeLabel">{{ timeLabel }}</span></ion-datetime
    >
  </ng-template>
</ion-modal>

<!-- End Date Modal -->
<ion-modal *ngIf="showSecondDate" [keepContentsMounted]="true" trigger="end-date-modal">
  <ng-template>
    <ion-datetime
      id="cid-end-datetime-modal-instance"
      [presentation]="presentationType"
      [showDefaultTitle]="showDefaultTitle"
      [showDefaultButtons]="showDefaultButtons"
      [preferWheel]="preferWheel"
      [(ngModel)]="secondDate"
      [min]="secondDateMin"
      [max]="secondDateMax"
      [locale]="localeCode"
      [firstDayOfWeek]="firstDayOfWeek"
      [doneText]="doneTextLabel"
      [preferWheel]="preferWheel"
      [formatOptions]="{
        date: {
          weekday: 'short',
          month: 'long',
          day: '2-digit'
        }
      }"
      (ionChange)="handleEndDateChange($event)">
      <span slot="title">{{ secondDateModalTitle }}</span>
      <span slot="time-label" *ngIf="showTimeLabel">{{ timeLabel }}</span></ion-datetime
    >
  </ng-template>
</ion-modal>
