/*
 * Public API Surface of bp-angular-library
 */

/**
 * SHARED
 */

// Animations
export * from './lib/shared/animations/animations';

// Configuration
export * from './lib/shared/configuration/configuration.const';
export * from './lib/shared/configuration/configuration.utils';

// Components
export * from './lib/shared/components/date-picker/date-picker.component';
export * from './lib/shared/components/typeahead-select/typeahead-select.component';
export * from './lib/shared/components/theme-toggle/theme-toggle.component';
export * from './lib/shared/components/flag-img/flag-img.component';
export * from './lib/shared/components/countries-iso-3166/countries-iso-3166.component';
export * from './lib/shared/components/generic-pagination/generic-pagination.component';

// Directives
export * from './lib/shared/directives/move-on-scroll.directive';

// Services
export * from './lib/shared/services/dialogs/dialogs.service';
export * from './lib/shared/services/storage/storage.service';
export * from './lib/shared/services/auth/auth.service';

// Interceptors
export * from './lib/shared/interceptors/auth/authentication.interceptor';

// Guards
export * from './lib/shared/guards/auth/authentication.guard';
export * from './lib/shared/guards/guard.utils';

// Features - Authentication
export * from './lib/shared/components/authentication/login-section/login-section.component';
export * from './lib/shared/components/authentication/signup-section/signup-section.component';
export * from './lib/shared/components/authentication/password-reset-section/password-reset-section.component';

// Features - Internationalization (i18n)
export * from './lib/shared/services/i18n/i18n.service';
export * from './lib/shared/components/language-button/language-button.component';
export * from './lib/shared/components/language-options-dialog/language-options-dialog.component';

// Features - Forms
export * from './lib/shared/forms/forms.utils';

// #region 1x2Team
export * from './lib/env-specific/1x2team/services/api/core-api.service';
export * from './lib/env-specific/1x2team/services/api/surfpoint-api.service';
export * from './lib/env-specific/1x2team/services/api/admin-api.service';
export * from './lib/env-specific/1x2team/services/api/feed-api.service';
export * from './lib/env-specific/1x2team/services/api/casino-api.service';
export * from './lib/env-specific/1x2team/services/auth/surfpoint-auth.service';
export * from './lib/env-specific/1x2team/interceptors/surfpoint-auth.interceptor';
// #endregion 1x2Team

// #region BetPlatform
export * from './lib/env-specific/betplatform/services/api/betplatform-api.service';
export * from './lib/env-specific/betplatform/services/api/betplatform-api-adapter.service';
// #endregion BetPlatform
