import { Injectable, inject } from '@angular/core';
import { IBpTokenPayload, IBpUserProfile } from 'bp-framework/dist/env-specific/betplatform/user/user.interface';
import { IBpPlayerProfile, IBpReferredPlayer } from 'bp-framework/dist/env-specific/betplatform/player/player.interface';
import { adaptBpPayload } from 'bp-framework/dist/env-specific/betplatform/api/api.mappers';

import { IApiPayload, IApiRequestParams } from 'bp-framework/dist/api/api.interface';

import { IBpPayload } from 'bp-framework/dist/env-specific/betplatform/api/api.interface';
import { IBpAffiliateCode } from 'bp-framework/dist/env-specific/betplatform/affiliates/affiliates.interface';

import { transformToTransactionDetails } from 'bp-framework/dist/env-specific/betplatform/transactions/transactions.mappers';

import {
  IBpCasinoGame,
  IBpCasinoLaunchDetails,
  IBpCasinoTag,
  IBpGamesWithTags,
  IBpJackpotDetailsWhenAgent,
  IBpJackpotItem,
  IBpCasinoSearchParams as IBPCasinoGamesSearchParams,
  IBpCasinoSearchParams
} from 'bp-framework/dist/env-specific/betplatform/casino/casino.interface';

import {
  CasinoTagType,
  ICasinoGame,
  ICasinoGameLaunchDetails,
  ICasinoGamesAndTags,
  IApiCasinoRequestParams,
  ICasinoTag,
  IJackpot,
  IJackpotDetailsForAdmin
} from 'bp-framework/dist/casino/casino.interface';
import {
  adjustParamsForBetplatform,
  mapCasinoGames,
  transformPayloadToCasinoGamesAndTags,
  transformPayloadToCasinoTags
} from 'bp-framework/dist/env-specific/betplatform/casino/casino.mappers';
import { bpMapPlayerProfileToUserDetails } from 'bp-framework/dist/env-specific/betplatform/player/player.mappers';
import { IBpCryptoDepositInit, IBpTransaction, IBpTransactionInitiate } from 'bp-framework/dist/env-specific/betplatform/transactions/transactions.interface';
import { ITransactionDetails, TransactionType } from 'bp-framework/dist/transactions/transactions.interface';
import { IUserDetails } from 'bp-framework/dist/user/user.interface';
import { IAffiliateCode, IReferredPlayer } from 'bp-framework/dist/player/player.interface';
import { BetPlatformApiService } from './betplatform-api.service';
import { bpExtractUserDetailsFromToken, bpMapUserProfileToUserDetails } from 'bp-framework/dist/env-specific/betplatform/user/user.mappers';
import { IBpEventSubscriptionsPayload } from 'bp-framework/dist/env-specific/betplatform/subscriptions/subscriptions.interface';
import { IBpGreekKenoRound, IBpKenoCouponSubmit, IBpKenoCoupon, IBpKenoCouponStatus } from 'bp-framework/dist/env-specific/betplatform/greek-keno/greek-keno.interface';
import { ITransactionGroup } from 'bp-framework/dist/transactions/transaction-groups.interface';
import { IBpTransactionGroup } from 'bp-framework/dist/env-specific/betplatform/transactions/transaction-groups.interface';

@Injectable({
  providedIn: 'root'
})
export class BetPlatformApiAdapterService {
  private bpApiService: BetPlatformApiService = inject(BetPlatformApiService);

  //#region USER | Authentication
  public async userLoginWithUsernameAndPassword(username: string, password: string): Promise<IApiPayload<Partial<IUserDetails>>> {
    const loginPayload: IBpPayload<IBpTokenPayload> | null = await this.bpApiService.authenticateWithUsernameAndPassword(username, password);
    return adaptBpPayload(loginPayload, bpExtractUserDetailsFromToken(loginPayload?.data));
  }

  public async userRefreshToken(refreshToken: string): Promise<IApiPayload<Partial<IUserDetails>>> {
    const loginPayload: IBpPayload<IBpTokenPayload> | null = await this.bpApiService.refreshToken(refreshToken);
    return adaptBpPayload(loginPayload, bpExtractUserDetailsFromToken(loginPayload?.data));
  }

  public async userChangePassword(oldPassword: string, newPassword: string): Promise<IApiPayload<unknown>> {
    const result: IBpPayload<unknown> | null = await this.bpApiService.changePassword(oldPassword, newPassword);
    return adaptBpPayload(result, result?.data);
  }
  //#endregion

  //#region USER | Profile
  public async userGetProfile(): Promise<IApiPayload<Partial<IUserDetails>>> {
    const result: IBpPayload<Partial<IBpUserProfile>> | null = await this.bpApiService.getUserProfile();
    return adaptBpPayload(result, bpMapUserProfileToUserDetails(result?.data));
  }

  public async userPatchLanguage(language: string): Promise<IApiPayload<Partial<IUserDetails>>> {
    // TODO: IMPORTANT: We need to have some abstract logic that will instead of patching just the language, convert this method to 'patch user profile' and allow to patch any field of the user profile
    const result: IBpPayload<Partial<IBpUserProfile>> | null = await this.bpApiService.patchUserLanguage({ language });
    return adaptBpPayload(result, bpMapUserProfileToUserDetails(result?.data));
  }
  //#endregion

  //#region PLAYER | Authentication
  public async playerRegisterWithUsernameAndPassword(username: string, password: string, phone: string, affiliateCode: string): Promise<IApiPayload<Partial<IUserDetails>>> {
    const registerPayload: IBpPayload<IBpPlayerProfile> | null = await this.bpApiService.playerRegisterWithUsernameAndPassword(username, password, phone, affiliateCode);
    return adaptBpPayload(registerPayload, bpMapPlayerProfileToUserDetails(registerPayload?.data));
  }
  //#endregion

  //#region PLAYER | Profile
  public async playerGetProfile(): Promise<IApiPayload<Partial<IUserDetails>>> {
    const getProfilePayload: IBpPayload<IBpPlayerProfile> | null = await this.bpApiService.getPlayerProfile();
    return adaptBpPayload(getProfilePayload, bpMapPlayerProfileToUserDetails(getProfilePayload?.data));
  }
  //#endregion

  //#region PLAYER | Transactions
  public async playerGetTransactions(
    startDateIso: string,
    endDateIso: string,
    limit: number,
    offset: number,
    transactionTypes: TransactionType[],
    transactionGroupIds: string[]
  ): Promise<IApiPayload<Partial<ITransactionDetails>[]>> {
    const payload: IBpPayload<IBpTransaction[]> | null = await this.bpApiService.getPlayerTransactions(
      startDateIso,
      endDateIso,
      limit,
      offset,
      transactionTypes,
      transactionGroupIds
    );
    const transformedData: Partial<ITransactionDetails>[] = payload?.data?.map((transaction: IBpTransaction) => transformToTransactionDetails(transaction)) || [];
    return adaptBpPayload(payload, transformedData);
  }

  public async playerInitiateCryptoDeposit(): Promise<IApiPayload<IBpCryptoDepositInit>> {
    const payload: IBpPayload<IBpCryptoDepositInit> | null = await this.bpApiService.cryptoDepositInitiate();
    // TODO: IMPORTANT: Check do we need some generic method that will transform the payload to the desired format instead of doing it manually here
    return adaptBpPayload(payload, payload?.data || { acquiringUrl: '' });
  }

  public async playerInitiateCryptoWithdrawal(walletId: number, amount: number, cryptoWalletAddress: string): Promise<IApiPayload<{ requestId?: string }>> {
    // TODO: IMPORTANT: Check do we need an interface instead of returning { requestId: string }
    const payload: IBpPayload<IBpTransactionInitiate> | null = await this.bpApiService.cryptoWithdrawInitiate(walletId, amount, cryptoWalletAddress);
    return adaptBpPayload(payload, { requestId: payload?.data?.request_id });
  }

  public async playerSubmitCryptoWithdrawal(requestId: string): Promise<IApiPayload<Partial<ITransactionDetails>>> {
    const payload: IBpPayload<IBpTransaction> | null = await this.bpApiService.cryptoWithdrawSubmit(requestId);
    return adaptBpPayload(payload, transformToTransactionDetails(payload?.data));
  }

  public async playerCancelCryptoWithdrawal(requestId: string): Promise<IApiPayload<Partial<ITransactionDetails>>> {
    const payload: IBpPayload<IBpTransaction> | null = await this.bpApiService.cryptoWithdrawCancel(requestId);
    return adaptBpPayload(payload, transformToTransactionDetails(payload?.data));
  }
  //#endregion

  //#region PLAYER | Referral
  public async playerGetReferredPlayers(params: IApiRequestParams): Promise<IApiPayload<IReferredPlayer[]>> {
    const result: IBpPayload<IBpReferredPlayer[]> | null = await this.bpApiService.getListOfPlayersReferredByPlayer(params);
    // TODO: IMPORTANT: Check do we need some generic method that will transform the payload to the desired format instead of doing it manually here
    const transformedData: IReferredPlayer[] =
      result?.data?.map((player: IBpReferredPlayer) => {
        const tmpPlayer: IReferredPlayer = {
          id: player.id,
          username: player.username,
          agentId: player.agent_id,
          affiliateParentUserId: player.affiliate_parent_user_id,
          registeredAt: player.registered_at
        };

        return tmpPlayer;
      }) || [];
    return adaptBpPayload(result, transformedData);
  }

  public async playerGetAffiliateCodes(params: IApiRequestParams): Promise<IApiPayload<IAffiliateCode[]>> {
    const result: IBpPayload<IBpAffiliateCode[]> | null = await this.bpApiService.getListOfPlayersAffiliateCodes(params);
    // TODO: IMPORTANT: Check do we need some generic method that will transform the payload to the desired format instead of doing it manually here
    const transformedData: IAffiliateCode[] =
      result?.data?.map((code: IBpAffiliateCode) => {
        return {
          id: code.id,
          code: code.code,
          userId: code.user_id,
          username: code.username,
          createdAt: code.created_at,
          updatedAt: code.updated_at
        };
      }) || [];
    return adaptBpPayload(result, transformedData);
  }
  //#endregion

  //#region PLAYER | Favorites
  // TODO: IMPORTANT: We should also add method to fetch the list of favorite games with details not just the ids
  public async playerGetIdsOfFavoriteGames(params: IApiRequestParams): Promise<IApiPayload<number[]>> {
    const response: IBpPayload<number[]> | null = await this.bpApiService.getFavoriteGames(params);
    return adaptBpPayload(response, response?.data || []);
  }

  public async playerGetFavoriteGames(params: IApiCasinoRequestParams): Promise<IApiPayload<ICasinoGamesAndTags>> {
    const tmpParams: IBPCasinoGamesSearchParams = adjustParamsForBetplatform({ ...params, favorites: true });
    const response: IBpPayload<IBpGamesWithTags> | null = await this.bpApiService.getAllCasinoGames(tmpParams);
    return adaptBpPayload(response, transformPayloadToCasinoGamesAndTags(response?.data));
  }

  public async playerAddGameToFavotires(gameId: number): Promise<IApiPayload<unknown>> {
    const response: IBpPayload<unknown> | null = await this.bpApiService.addGameToFavorites(gameId);
    return adaptBpPayload(response, response?.data);
  }

  public async removeGameFromFavorites(gameId: number): Promise<IApiPayload<unknown>> {
    const response: IBpPayload<unknown> | null = await this.bpApiService.removeGameFromFavorites(gameId);
    return adaptBpPayload(response, response?.data);
  }
  //#endregion

  //#region PLAYER | Keno
  public async getKenoRounds(params?: { limit?: number }): Promise<IApiPayload<IBpGreekKenoRound[]>> {
    const response: IBpPayload<IBpGreekKenoRound[]> | null = await this.bpApiService.getKenoRounds(params);
    return adaptBpPayload(response, response?.data || []);
  }

  public async submitKenoCoupon(numbers: number[], stake: number): Promise<IApiPayload<IBpKenoCouponSubmit | null>> {
    const response: IBpPayload<IBpKenoCouponSubmit> | null = await this.bpApiService.submitKenoCoupon(numbers, stake);
    return adaptBpPayload(response, response?.data || null);
  }

  public async getKenoCoupons(params?: { status?: IBpKenoCouponStatus; limit?: number }): Promise<IApiPayload<IBpKenoCoupon[]>> {
    const response: IBpPayload<IBpKenoCoupon[]> | null = await this.bpApiService.getKenoCoupons(params);
    return adaptBpPayload(response, response?.data || []);
  }
  //#endregion

  //#region ADMIN | Players
  public async adminRegisterPlayer(username: string, password: string, language: string, phoneNumber: string): Promise<IApiPayload<Partial<IUserDetails>>> {
    const response: IBpPayload<Partial<IBpPlayerProfile>> | null = await this.bpApiService.addPlayer(username, password, language, phoneNumber);
    return adaptBpPayload(response, bpMapPlayerProfileToUserDetails(response?.data));
  }

  public async adminGetPlayersList(params: IApiRequestParams): Promise<IApiPayload<Partial<IUserDetails>[]>> {
    const response: IBpPayload<Partial<IBpPlayerProfile>[]> | null = await this.bpApiService.getPlayers(params);
    const transformedData: Partial<IUserDetails>[] = response?.data?.map((player: Partial<IBpPlayerProfile>) => bpMapPlayerProfileToUserDetails(player)) || [];
    return adaptBpPayload(response, transformedData);
  }

  public async adminGetPlayerById(playerId: string): Promise<IApiPayload<Partial<IUserDetails>>> {
    const response: IBpPayload<Partial<IBpPlayerProfile>> | null = await this.bpApiService.getPlayer(playerId);
    return adaptBpPayload(response, bpMapPlayerProfileToUserDetails(response?.data));
  }

  public async adminMakePlayerDeposit(playerId: string, amount: number): Promise<IApiPayload<null>> {
    const response: IBpPayload<unknown> | null = await this.bpApiService.makePlayerDeposit(playerId, amount);
    // TODO: IMPORTANT: Check do we need some generic method that will transform the payload to the desired format instead of doing it manually here
    return adaptBpPayload(response, null);
  }

  public async adminMakePlayerWithdrawal(playerId: string, amount: number): Promise<IApiPayload<null>> {
    const response: IBpPayload<unknown> | null = await this.bpApiService.makePlayerWithdrawal(playerId, amount);
    // TODO: IMPORTANT: Check do we need some generic method that will transform the payload to the desired format instead of doing it manually here
    return adaptBpPayload(response, null);
  }

  public async adminBlockPlayer(playerId: string): Promise<IApiPayload<null>> {
    const response: IBpPayload<unknown> | null = await this.bpApiService.blockPlayer(playerId);
    // TODO: IMPORTANT: Check do we need some generic method that will transform the payload to the desired format instead of doing it manually here
    return adaptBpPayload(response, null);
  }

  public async adminUnblockPlayer(playerId: string): Promise<IApiPayload<null>> {
    const response: IBpPayload<unknown> | null = await this.bpApiService.unblockPlayer(playerId);
    // TODO: IMPORTANT: Check do we need some generic method that will transform the payload to the desired format instead of doing it manually here
    return adaptBpPayload(response, null);
  }
  //#endregion

  //#region ADMIN | Transactions
  public async adminGetTransactions(
    startDateIso: string,
    endDateIso: string,
    limit: number,
    offset: number,
    transactionTypes: TransactionType[],
    transaction_group_ids: string[],
    userIds: string[]
  ): Promise<IApiPayload<Partial<ITransactionDetails>[]>> {
    const payload: IBpPayload<IBpTransaction[]> | null = await this.bpApiService.adminGetTransactions(
      startDateIso,
      endDateIso,
      limit,
      offset,
      transactionTypes,
      transaction_group_ids,
      userIds
    );
    const transformedData: Partial<ITransactionDetails>[] = payload?.data?.map((transaction: IBpTransaction) => transformToTransactionDetails(transaction)) || [];
    return adaptBpPayload(payload, transformedData);
  }
  //#endregion

  //#region ADMIN | Jackpots
  public async adminGetJackpotsList(params: IApiRequestParams): Promise<IApiPayload<IJackpotDetailsForAdmin[]>> {
    const response: IBpPayload<IBpJackpotDetailsWhenAgent[]> | null = await this.bpApiService.getJackpotsListForAgent(params);

    // TODO: IMPORTANT: Check do we need some generic method that will transform the payload to the desired format instead of doing it manually here
    const jackpots: IJackpotDetailsForAdmin[] =
      response?.data.map((jackpot: IBpJackpotDetailsWhenAgent) => {
        const tmpData: IJackpotDetailsForAdmin = {
          id: jackpot?.jackpot_id,
          name: jackpot?.jackpot_name,
          minValue: jackpot?.jackpot_min_value,
          maxValue: jackpot?.jackpot_max_value,
          defaultContribution: jackpot?.jackpot_default_contribution,
          contributionAgentOverride: jackpot?.jackpot_contribution_agent_override,
          enabled: jackpot?.jackpot_enabled,
          public: jackpot?.jackpot_public,
          roundId: jackpot?.jackpot_round_id,
          roundValue: jackpot?.jackpot_round_value,
          roundCreatedAt: jackpot?.jackpot_round_created_at,
          roundUpdatedAt: jackpot?.jackpot_round_updated_at
        };

        return tmpData;
      }) || [];

    return adaptBpPayload(response, jackpots);
  }

  public async adminAwardJackpot(playerId: string, jackpotId: number): Promise<IApiPayload<unknown>> {
    const response: IBpPayload<unknown> | null = await this.bpApiService.awardJackpot(playerId, jackpotId);
    return adaptBpPayload(response, response?.data);
  }
  //#endregion

  //#region CASINO | Games
  public async casinoGetGamesByParams(params: IApiCasinoRequestParams): Promise<IApiPayload<ICasinoGamesAndTags>> {
    const tmpParams: IBpCasinoSearchParams = adjustParamsForBetplatform(params);
    const response: IBpPayload<IBpGamesWithTags> | null = await this.bpApiService.getAllCasinoGames(tmpParams);
    return adaptBpPayload(response, transformPayloadToCasinoGamesAndTags(response?.data));
  }

  public async casinoGetGameById(gameId: number): Promise<IApiPayload<Partial<ICasinoGame> | null>> {
    const response: IBpPayload<IBpCasinoGame> | null = await this.bpApiService.getCasinoGameById(gameId);
    const tmpGame: Partial<ICasinoGame> | null = response?.data ? mapCasinoGames([response?.data])[0] : null;
    return adaptBpPayload(response, tmpGame);
  }

  public async casinoGetGamePlayUrl(gameId: number, mode: 'real' | 'demo'): Promise<IApiPayload<ICasinoGameLaunchDetails>> {
    const response: IBpPayload<IBpCasinoLaunchDetails> | null = await this.bpApiService.getCasinoGamePlayUrl(gameId, mode);
    const tmpData: ICasinoGameLaunchDetails = { url: response?.data?.launchUrl || '', token: response?.data?.token || '' };
    return adaptBpPayload(response, tmpData);
  }
  //#endregion

  //#region CASINO | Tags
  public async casinoGetTypesOfTags(): Promise<IApiPayload<CasinoTagType[]>> {
    // TODO: Revisit return types here. The "CasinoTagType" is not the same as "CasinoTagTypes" from the API
    const response: IBpPayload<CasinoTagType[]> | null = await this.bpApiService.getCasinoTypesOfTags();
    return adaptBpPayload(response, response?.data || []);
  }

  public async casinoGetPrimaryPages(params?: IApiCasinoRequestParams): Promise<IApiPayload<ICasinoTag[]>> {
    const tmpParams: IBPCasinoGamesSearchParams = adjustParamsForBetplatform({ ...params, tagType: 'page' });
    const response: IBpPayload<IBpCasinoTag[]> | null = await this.bpApiService.getCasinoTags(tmpParams);
    return adaptBpPayload(response, transformPayloadToCasinoTags(response?.data));
  }

  public async casinoGetAllCasinoGroups(params?: IApiCasinoRequestParams): Promise<IApiPayload<ICasinoTag[]>> {
    const tmpParams: IBPCasinoGamesSearchParams = adjustParamsForBetplatform({ ...params, tagType: 'group' });
    const response: IBpPayload<IBpCasinoTag[]> | null = await this.bpApiService.getCasinoTags(tmpParams);
    return adaptBpPayload(response, transformPayloadToCasinoTags(response?.data));
  }

  public async casinoGetCasinoSubCategoriesByCategoryId(categoryId: number, params?: IApiCasinoRequestParams): Promise<IApiPayload<ICasinoTag[]>> {
    const tmpParams: IBPCasinoGamesSearchParams = adjustParamsForBetplatform({ ...params, tagIds: [`${categoryId}`], tagType: 'sub-category' });
    const response: IBpPayload<IBpCasinoTag[]> | null = await this.bpApiService.getCasinoTags(tmpParams);
    return adaptBpPayload(response, transformPayloadToCasinoTags(response?.data));
  }

  public async casinoGetVendorsByCategoryAndSubcategory(categoryId: string, subCategoryId: string, params?: IApiCasinoRequestParams): Promise<IApiPayload<ICasinoTag[]>> {
    const tmpParams: IBPCasinoGamesSearchParams = adjustParamsForBetplatform({ ...params, tagIds: [categoryId, subCategoryId], tagType: 'vendor' });
    const response: IBpPayload<IBpCasinoTag[]> | null = await this.bpApiService.getCasinoTags(tmpParams);
    return adaptBpPayload(response, transformPayloadToCasinoTags(response?.data));
  }

  public async casinoGetCasinoGameVendors(params?: IApiCasinoRequestParams): Promise<IApiPayload<ICasinoTag[]>> {
    const tmpParams: IBPCasinoGamesSearchParams = adjustParamsForBetplatform({ ...params, tagType: 'vendor' });
    const response: IBpPayload<IBpCasinoTag[]> | null = await this.bpApiService.getCasinoTags(tmpParams);
    return adaptBpPayload(response, transformPayloadToCasinoTags(response?.data));
  }
  //#endregion

  //#region CASINO | Jackpots
  public async casinoGetJackpotsGlobal(params: IApiRequestParams): Promise<IApiPayload<IJackpot[]>> {
    const response: IBpPayload<IBpJackpotItem[]> | null = await this.bpApiService.getJackpotsListForPlayers(params);
    const transformedList: IJackpot[] | undefined = response?.data?.map((jackpot: IBpJackpotItem) => {
      const tmpItem: IJackpot = { id: jackpot.id, name: jackpot.name, value: jackpot.value };
      return tmpItem;
    });
    return adaptBpPayload(response, transformedList || []);
  }
  //#endregion

  //#region BACKEND COMMUNICATION
  public async subscribeToBackendEvents(): Promise<IBpPayload<IBpEventSubscriptionsPayload> | null> {
    const response: IBpPayload<IBpEventSubscriptionsPayload> | null = await this.bpApiService.subscribeToBackendEvents();
    // TODO: IMPORTANT: Check do we need some generic method that will transform the payload to the desired format instead of doing it manually here
    return response;
  }
  //#endregion

  //#region FINANCE | Transactions
  public async getFinanceTransactionGroups(): Promise<IApiPayload<ITransactionGroup[]> | null> {
    const response: IBpPayload<IBpTransactionGroup[]> | null = await this.bpApiService.getFinanceTransactionGroups();
    const transformedList: ITransactionGroup[] | undefined = response?.data?.map((group: IBpTransactionGroup) => {
      const tmpItem: ITransactionGroup = { id: group.transaction_group_id, title: group.title, description: group.description };
      return tmpItem;
    });
    return adaptBpPayload(response, transformedList || []);
  }
  //#endregion
}
