import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IApiPayload } from 'bp-framework/dist/api/api.interface';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'lib-generic-pagination',
  standalone: true,
  imports: [CommonModule, FormsModule, IonicModule, TranslateModule],
  templateUrl: './generic-pagination.component.html',
  styleUrls: ['./generic-pagination.component.scss']
})
export class GenericPaginationComponent<T> {
  @Input() set payload(value: IApiPayload<T>) {
    if (value) {
      this.total = value.total || 0;
      this.limit = value.limit || this.pageSizes[0];
      // Calculate current page from offset and limit
      const offset = value.offset || 0;
      this.currentPage = Math.floor(offset / this.limit) + 1;

      this.calculatePagination();
    }
  }

  @Output() pageChange = new EventEmitter<{
    offset: number;
    limit: number;
  }>();

  pageSizes = [10, 25, 50, 100];
  currentPage = 1;
  totalPages = 1;
  total = 0;
  limit = this.pageSizes[0];
  rangeStart = 1;
  rangeEnd = 1;

  private calculatePagination(): void {
    this.totalPages = Math.ceil(this.total / this.limit);
    this.rangeStart = (this.currentPage - 1) * this.limit + 1;
    this.rangeEnd = Math.min(this.currentPage * this.limit, this.total);

    if (this.total === 0) {
      this.rangeStart = 0;
      this.rangeEnd = 0;
    } else {
      this.rangeStart = Math.max(1, this.rangeStart);
    }
  }

  handlePageChange(page: number): void {
    if (page !== this.currentPage && page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      this.calculatePagination();
      this.emitPageChange();
    }
  }

  handlePageSizeChange(newLimit: number): void {
    this.limit = newLimit;
    this.currentPage = 1;
    this.calculatePagination();
    this.emitPageChange();
  }

  private emitPageChange(): void {
    const offset = (this.currentPage - 1) * this.limit;

    this.pageChange.emit({
      offset,
      limit: this.limit
    });
  }
}
