import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { PROJECT_ENV_CONFIG_TOKEN } from '../../../../shared/configuration/configuration.const';
import { CasinoCategory, IBpCasinoGame, CasinoGamePlay, CasinoPayload, ICasinoJackpot } from 'bp-framework/dist/env-specific/1x2team/casino/casino.interface';
import { firstValueFrom } from 'rxjs';
import { assembleRequestInAbstractWay } from '../../../../shared/services/api/api.service.utils';
import { IEnvConfig } from 'bp-framework/dist/configuration/configuration.interface';
import { IEnvApiOn1x2Team } from 'bp-framework/dist/env-specific/1x2team/configuration/configuration.interface';

@Injectable({
  providedIn: 'root'
})
export class CasinoApi1x2TeamService {
  private projectConfig: IEnvConfig<IEnvApiOn1x2Team> = inject<IEnvConfig<IEnvApiOn1x2Team>>(PROJECT_ENV_CONFIG_TOKEN);
  private casinoBaseUrl = this.projectConfig?.api?.baseUrls?.casino;

  constructor(private httpClient: HttpClient) {}

  public getCasinoCategories(): Promise<CasinoPayload<CasinoCategory> | null> {
    const fullPath = `${this.casinoBaseUrl}/gaming/api/casino_categories`;
    return firstValueFrom(assembleRequestInAbstractWay<CasinoPayload<CasinoCategory>>(this.httpClient, 'GET', fullPath));
  }

  public getAllCasinoGames(): Promise<CasinoPayload<IBpCasinoGame> | null> {
    const fullPath = `${this.casinoBaseUrl}/gaming/api/casino_games`;
    return firstValueFrom(assembleRequestInAbstractWay<CasinoPayload<IBpCasinoGame>>(this.httpClient, 'GET', fullPath));
  }

  public getCasinoGamePlayUrl(gameId: number): Promise<CasinoGamePlay | null> {
    const fullPath = `${this.casinoBaseUrl}/gaming/api/create/${gameId}/0`;
    return firstValueFrom(
      assembleRequestInAbstractWay<CasinoGamePlay>(this.httpClient, 'POST', fullPath, {
        body: { gameId }
      })
    );
  }

  public getJackpot(agentUsername: string): Promise<ICasinoJackpot | null> {
    const fullPath = `${this.casinoBaseUrl}/surfpoint/jackpot?username=${agentUsername}`;
    return firstValueFrom(assembleRequestInAbstractWay<ICasinoJackpot>(this.httpClient, 'GET', fullPath));
  }
}
