import { CommonModule } from '@angular/common';
import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import * as listOfCountries from '../../../../assets/countries-iso-3166/countries-is-3166-slim.json';
import { BehaviorSubject, debounceTime } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FlagImgComponent } from '../flag-img/flag-img.component';
import { ICountry } from 'bp-framework/dist/location/location.interface';

export interface ICountriIso3166 {
  name: string;
  alpha2: string;
  alpha3: string;
  countryCode: string;
  region: string | null;
}

@Component({
  selector: 'lib-countries-iso-3166',
  standalone: true,
  imports: [CommonModule, IonicModule, FlagImgComponent],
  templateUrl: './countries-iso-3166.component.html',
  styleUrl: './countries-iso-3166.component.scss'
})
export class CountriesIso3166Component implements OnInit {
  private destroyRef: DestroyRef = inject(DestroyRef);
  @Input() set keyword(value: string) {
    this.keyword$.next(value);
  }

  @Output() handleCountryChanged: EventEmitter<ICountry> = new EventEmitter<ICountry>();

  public unfilteredListOfCountries: ICountriIso3166[] = (listOfCountries as any).default;
  public filteredListOfCountries: ICountriIso3166[] = [];
  private keyword$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public selectedCountry: ICountriIso3166 | null = null;

  ngOnInit(): void {
    this.initialize();
  }

  private initialize(): void {
    this.keyword$.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(100)).subscribe((keyword: string) => {
      this.filterCountriesByKeyword(keyword);
    });
  }

  public filterCountriesByKeyword(keyword: string): void {
    if (!keyword) {
      this.filteredListOfCountries = JSON.parse(JSON.stringify(this.unfilteredListOfCountries));

      return;
    }

    this.filteredListOfCountries = this.unfilteredListOfCountries.filter((country: ICountriIso3166) => {
      return country.name.toLowerCase().includes(keyword.toLowerCase());
    });
  }

  public handleCountrySelected(event: Event): void {
    this.selectedCountry = (event as CustomEvent)?.detail?.value;

    if (!this.selectedCountry) {
      return;
    }

    const tmpCountry: ICountry = {
      name: this.selectedCountry.name,
      alpha2Code: this.selectedCountry.alpha2
    };

    this.handleCountryChanged.emit(tmpCountry);
  }
}
