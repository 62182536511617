import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { EMPTY_STRING } from 'bp-framework/dist/common/common.const';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'lib-login-section',
  standalone: true,
  imports: [CommonModule, IonicModule, FormsModule, ReactiveFormsModule, TranslateModule],
  templateUrl: './login-section.component.html',
  styleUrls: ['./login-section.component.scss']
})
export class LoginSectionComponent implements OnInit {
  @Input() submitBtnColor = 'success';

  @Output() handleFormSubmit: EventEmitter<{ username: string; password: string }> = new EventEmitter();

  public formGroup!: FormGroup<{
    username: FormControl<string | null>;
    password: FormControl<string | null>;
  }>;

  public passwordInputMode: 'text' | 'password' = 'password';

  constructor(private formBuilder: FormBuilder) {}

  //#region lifecycle hooks
  ngOnInit(): void {
    this.initializeForm();
  }
  //#endregion

  //#region methods
  private initializeForm(): void {
    this.formGroup = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
  }

  public async handleLogin(): Promise<void> {
    if (!this.formGroup.valid) {
      return;
    }

    const username: string = this.formGroup.controls.username.value || EMPTY_STRING;
    const password: string = this.formGroup.controls.password.value || EMPTY_STRING;

    this.handleFormSubmit.emit({ username, password });
  }

  public togglePasswordInputMode(): void {
    this.passwordInputMode = this.passwordInputMode === 'password' ? 'text' : 'password';
  }
  //#endregion
}
