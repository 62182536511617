import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../../services/auth/auth.service';
import { PROJECT_ENV_CONFIG_TOKEN } from '../../configuration/configuration.const';
import { ApiProvider, IEnvApiBase, IEnvConfig } from 'bp-framework/dist/configuration/configuration.interface';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  private projectConfig: IEnvConfig<IEnvApiBase> = inject<IEnvConfig<IEnvApiBase>>(PROJECT_ENV_CONFIG_TOKEN);

  private acceptableUrls: string[] = this.projectConfig?.httpInterceptors?.attachAuthTokenToTheseUrls;
  private unacceptableUrls: string[] = this.projectConfig?.httpInterceptors?.doNotAttachAuthTokenToTheseUrls;

  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isThisACallToAcceptableUrl: boolean = this.acceptableUrls.some((url: string): boolean => request.url.startsWith(url));

    if (!isThisACallToAcceptableUrl) {
      // Ukoliko poziv nije upucen nasem APIu, onda ne treba da radimo nista
      return next.handle(request);
    }

    const isThisACallToUrlWeWantToSkip: boolean = this.unacceptableUrls.some((url: string): boolean => request.url.startsWith(url));

    if (isThisACallToUrlWeWantToSkip) {
      // Ako je poziv upucen API endpointu koji treba da uloguje korisnika, onda tu nije potreban token u headeru
      return next.handle(request);
    }

    if (request.headers.has('Authorization')) {
      // Ako vec postoji header, onda nije potrebno nista da dodajemo
      return next.handle(request);
    }

    if (this.authService.rawToken$.value) {
      // U slucaju da token postoji, onda mozemo da ga dodamo u header
      request = request.clone({
        setHeaders: {
          Authorization: this.provideTokenValue(this.projectConfig?.api?.provider)
        }
      });
    }

    return next.handle(request).pipe(
      catchError((errorResponse: HttpErrorResponse): Observable<any> => {
        return this.handle401Error(errorResponse, request, next);
      })
    );
  }

  /**
   * @function handle401Error
   * @description Sadrzi logiku koja ce ukoliko uslovi dozvoljavaju, pokusati da ponovo uloguje korisnika ako je greska vezana za "401 Unauthorized"
   * @param {HttpErrorResponse} errorResponse - Greska koja je dobijena iz prvog poziva koji je neuspesno zavrsen
   * @param {HttpRequest} request - HTTP zahtev koji se salje
   * @param {HttpHandler} next - HTTP handler za poziv koji cemo modifikovati i ponovo poslati
   * @returns {Observable<any>}
   */
  private handle401Error(errorResponse: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    // TODO: Important - Check how to handle case when token expires on 1x2team backend.. we need to perform some silent login in background
    // TODO: Also, since we can't save user credentials on the system, we might want to save those credentials in mamery so while the application on 1x2team environment is running, we can perform silent login
    // TODO: Check what to do with token refresh for the 'betplatform' environment
    return throwError(() => errorResponse);
  }

  private provideTokenValue(apiProvider: ApiProvider): string {
    switch (apiProvider) {
      case '1x2team':
        return `token ${this.authService.rawToken$.value}`;
      case 'betplatform':
        return `Bearer ${this.authService.rawToken$.value}`;
      default:
        return '';
    }
  }
}
