/* eslint-disable no-async-promise-executor */
import { Storage } from '@ionic/storage';

import { inject, Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { EMPTY_STRING } from 'bp-framework/dist/common/common.const';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private ionicStorage: Storage = inject(Storage);
  private storage: Storage | null = null;
  private smk = 'verdanafont!ghost73513';

  private projectPrefix = 'tmp';

  public async initializeStorageService(projectPrefix: string): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      // If using, define drivers here: await this.storage.defineDriver(/*...*/);
      this.projectPrefix = projectPrefix;
      this.storage = await this.ionicStorage.create();
      resolve(true);
    });
  }

  public setLocalItem(key: string, data: any): Promise<void> {
    if (!this.storage) {
      return Promise.reject('Storage is not available');
    }

    const storageKey = `${this.projectPrefix}:${key}`;
    return this.storage.set(storageKey, this.processTo(data));
  }

  public getLocalItem(key: string): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      if (!this.storage) {
        return resolve(EMPTY_STRING);
      }

      const storageKey = `${this.projectPrefix}:${key}`;
      const tmpValue = await this.storage.get(storageKey);

      if (!tmpValue) {
        return resolve(EMPTY_STRING);
      }

      try {
        const processedValue = this.processFrom(tmpValue);
        const valueToReturn = this.isJsonString(processedValue) ? JSON.parse(processedValue) : processedValue;
        return resolve(valueToReturn);
      } catch (error) {
        return resolve(EMPTY_STRING);
      }
    });
  }

  public removeLocalItem(key: string): Promise<void> {
    if (!this.storage) {
      return Promise.reject('Storage is not available');
    }

    const storageKey = `${this.projectPrefix}:${key}`;
    return this.storage.remove(storageKey);
  }

  public clearAllLocalStorage(): Promise<void> {
    if (!this.storage) {
      return Promise.reject('Storage is not available');
    }

    return this.storage.clear();
  }

  private processTo(value: string): string {
    const tmpValue = typeof value === 'string' ? value : JSON.stringify(value);
    return CryptoJS.AES.encrypt(tmpValue, this.smk).toString();
  }

  private processFrom(value: string) {
    return CryptoJS.AES.decrypt(value, this.smk).toString(CryptoJS.enc.Utf8);
  }

  private isJsonString(str: string): boolean {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
}
