import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '../storage/storage.service';
import { STORAGE_KEYS } from 'bp-framework/dist/storage/storage.const';
import { LANGUAGE_ALPHA2CODE } from 'bp-framework/dist/i18n/i18n.const';
import { IListItem } from 'bp-framework/dist/common/common.interface';
import { Platform } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { LanguageOptionsComponent } from '../../components/language-options-dialog/language-options-dialog.component';
import { DialogsService } from '../dialogs/dialogs.service';
import { Subject } from 'rxjs';
import { getCountryAlpha2CodeByLanguageCode } from 'bp-framework/dist/i18n/i18n.utils';

@Injectable({
  providedIn: 'root'
})
export class I18nService {
  private translateService: TranslateService = inject(TranslateService);
  private storageService: StorageService = inject(StorageService);
  private platform: Platform = inject(Platform);
  private dialogsService: DialogsService = inject(DialogsService);

  public supportedLanguages: Partial<IListItem<string>>[] = [];
  public selectedLanguage!: Partial<IListItem<string>>;
  public selectedLanguageCountryAlpha2Code!: string;
  public saveNewlySelectedLanguageToUserProfile$: Subject<string> = new Subject<string>();

  public async initializeLanguage(supportedLanguages: Partial<IListItem<string>>[]): Promise<void> {
    this.supportedLanguages = supportedLanguages;
    // Check if there's a language saved in localStorage
    const savedLanguage: string = await this.storageService.getLocalItem(STORAGE_KEYS.langAndRegion);

    let languageToUseAlpha2Code: string;

    const savedLanguageExistsInSupportedLanguages = this.supportedLanguages.some((item: Partial<IListItem<string>>) => item.value === savedLanguage);

    if (savedLanguageExistsInSupportedLanguages) {
      // If a saved language exists and is supported, use it
      languageToUseAlpha2Code = savedLanguage;
    } else {
      // If no saved language, get the browser language
      const browserLanguage: string | undefined = this.translateService.getBrowserLang();
      const browserLanguageExistsInSupportedLanguages = this.supportedLanguages.some((item: Partial<IListItem<string>>) => item.value === savedLanguage);

      // Check if the browser language is supported
      if (browserLanguage && browserLanguageExistsInSupportedLanguages) {
        languageToUseAlpha2Code = browserLanguage;
      } else {
        // If not, set default language to 'en'
        languageToUseAlpha2Code = LANGUAGE_ALPHA2CODE.en;
      }
    }

    this.translateService.setDefaultLang(languageToUseAlpha2Code);
    this.changeLanguage(languageToUseAlpha2Code);
  }

  public changeLanguage(selectedLanguageAlpha2Code: string): void {
    this.selectedLanguage = this.supportedLanguages.find((item: Partial<IListItem<string>>) => item.value === selectedLanguageAlpha2Code) || this.supportedLanguages[0];
    this.translateService.use(selectedLanguageAlpha2Code);
    this.storageService.setLocalItem(STORAGE_KEYS.langAndRegion, selectedLanguageAlpha2Code);
    this.selectedLanguageCountryAlpha2Code = getCountryAlpha2CodeByLanguageCode(selectedLanguageAlpha2Code);
  }

  public showLanguageOptions(event: Event): void {
    if (this.platform.is('desktop')) {
      this.openLanguageOptionsAsPopover(event);
    } else {
      this.openLanguageOptionsAsModal();
    }
  }

  private async openLanguageOptionsAsPopover(event: Event): Promise<void> {
    const dialogResult: OverlayEventDetail<any> = await this.dialogsService.presentPopover({
      component: LanguageOptionsComponent,
      event,
      dismissOnSelect: true,
      cssClass: 'has-w340'
    });
  }

  private async openLanguageOptionsAsModal(): Promise<void> {
    const dialogResult: OverlayEventDetail<any> = await this.dialogsService.presentModal({
      component: LanguageOptionsComponent,
      componentProps: {
        showHeader: true
      },
      initialBreakpoint: 0.85,
      breakpoints: [0, 0.25, 0.5, 0.75, 0.85, 1],
      handleBehavior: 'cycle'
    });
  }
}
