import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule, ToastOptions } from '@ionic/angular';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { UserPersonaType } from 'bp-framework/dist/auth/auth.interface';
import { AuthService } from '../../../services/auth/auth.service';
import { DialogsService } from '../../../services/dialogs/dialogs.service';
import { EMPTY_STRING } from 'bp-framework/dist/common/common.const';

@Component({
  selector: 'lib-password-reset-section',
  standalone: true,
  imports: [CommonModule, IonicModule, FormsModule, ReactiveFormsModule, TranslateModule],
  templateUrl: './password-reset-section.component.html',
  styleUrls: ['./password-reset-section.component.scss']
})
export class PasswordResetSectionComponent implements OnInit {
  @Input() customSuccessFunc!: () => void;
  @Input() customErrorFunc!: (error: Error) => void;
  @Input() submitBtnColor = 'success';
  @Input() userPersonaType: UserPersonaType = 'player';

  public formGroup!: FormGroup<{
    oldPassword: FormControl<string | null>;
    newPassword: FormControl<string | null>;
    newPasswordConfirmation: FormControl<string | null>;
  }>;

  private formIsSubmitted!: boolean;

  public oldPasswordInputMode: 'text' | 'password' = 'password';
  public newPasswordInputMode: 'text' | 'password' = 'password';
  public newPasswordConfirmationInputMode: 'text' | 'password' = 'password';

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private dialogsService: DialogsService
  ) {}

  //#region lifecycle hooks
  ngOnInit(): void {
    this.initializeForm();
  }
  //#endregion

  //#region methods
  private initializeForm(): void {
    this.formGroup = this.formBuilder.group({
      oldPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required]],
      newPasswordConfirmation: ['', [Validators.required]]
    });
  }

  public async handleFormSubmit(): Promise<void> {
    if (!this.formGroup.valid || this.formIsSubmitted) {
      return;
    }

    this.formIsSubmitted = true;

    const oldPassword: string = this.formGroup.controls.oldPassword.value || EMPTY_STRING;
    const newPassword: string = this.formGroup.controls.newPassword.value || EMPTY_STRING;
    const newPasswordConfirmation: string = this.formGroup.controls.newPasswordConfirmation.value || EMPTY_STRING;

    await this.dialogsService.presentLoading();

    setTimeout(() => {
      this.formIsSubmitted = false;
      this.dialogsService.dismissLoading();
    }, 2000);
  }

  public handleSuccess(): void {
    if (typeof this.customSuccessFunc === 'function') {
      this.customSuccessFunc();
      return;
    }
  }

  public handleError(error: Error): void {
    if (typeof this.customErrorFunc === 'function') {
      this.customErrorFunc(error);
      return;
    }

    const options: ToastOptions = {
      message: error?.message,
      color: 'danger'
    };
    this.dialogsService.presentToast(options);
  }

  public togglePasswordInputMode(element: 'old' | 'new' | 'newConfirmation'): void {
    switch (element) {
      case 'old':
        this.oldPasswordInputMode = this.oldPasswordInputMode === 'password' ? 'text' : 'password';
        break;
      case 'new':
        this.newPasswordInputMode = this.newPasswordInputMode === 'password' ? 'text' : 'password';
        break;
      case 'newConfirmation':
        this.newPasswordConfirmationInputMode = this.newPasswordConfirmationInputMode === 'password' ? 'text' : 'password';
        break;
    }
  }
  //#endregion
}
