/* eslint-disable no-async-promise-executor */
import { isBefore } from 'date-fns';

import { BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';

import { EMPTY_STRING } from 'bp-framework/dist/common/common.const';
import { STORAGE_KEYS, SURF_POINT_STORAGE_KEYS } from 'bp-framework/dist/storage/storage.const';

import { IUserDetails } from 'bp-framework/dist/user/user.interface';
import { ILoginPayload, IProfile } from 'bp-framework/dist/env-specific/1x2team/user/user.interface';
import { transformLoginDataToUserDetails, updateUserDetailsWithProfileData } from 'bp-framework/dist/env-specific/1x2team/user/user.mappers';

import { ILoginCredentials, UserPersonaType } from 'bp-framework/dist/auth/auth.interface';

import { StorageService } from '../../../../shared/services/storage/storage.service';
import { CoreApi1x2TeamService } from '../api/core-api.service';

@Injectable({
  providedIn: 'root'
})
export class SurfpointAuthService {
  public user: Partial<IUserDetails> | null = null;

  public user$: BehaviorSubject<Partial<IUserDetails> | null> = new BehaviorSubject<Partial<IUserDetails> | null>(null);

  public isLoggedIn = false;
  public isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public rawToken$: BehaviorSubject<string> = new BehaviorSubject<string>(EMPTY_STRING);

  constructor(
    private storageService: StorageService,
    private apiService: CoreApi1x2TeamService
  ) {}

  /**
   * @async
   * @function userDetailsChanged
   * @description Takes the data received by param and updates the user related observables.
   * @param {Partial<IUserDetails> | null} value - Object containing user details
   * @returns {Promise<void>}
   */
  public async userDetailsChanged(data: Partial<IUserDetails> | null): Promise<void> {
    this.user = data === null ? null : { ...this.user, ...data };
    this.user$.next(this.user);

    console.log('user: ', this.user);

    if (this.user?.auth?.token) {
      return this.storageService.setLocalItem(STORAGE_KEYS.userDetails, this.user);
    } else {
      return this.storageService.removeLocalItem(STORAGE_KEYS.userDetails);
    }
  }

  public async userAuthChanged(data?: Partial<IUserDetails> | null): Promise<void> {
    this.rawToken$.next(data?.auth?.token ?? EMPTY_STRING);

    this.isLoggedIn = !!data?.auth?.token;
    this.isLoggedIn$.next(this.isLoggedIn);

    this.user = data?.auth?.token ? { ...this.user, ...data } : null;
    return await this.userDetailsChanged(this.user);
  }

  /**
   * @async
   * @function handleAppInitialization
   * @description Used during the app initializaiton. Covers the following scenarios:
   * 1. If the token exists in the local storage, then the user did not logout (manually clicked on the Log Out button).
   *    In this case we can assume that if the token is valid, we don't need ask user to login
   * 2. If the token doesn't exist, then the user must have clicked on the Log Out button (or removed data from local storage).
   *    Inside the 'userDetailsChanged' function, we remove token from the storage when the user logs out. Because of that, we will assume that since there is no token, user should be navigated to the login page
   * @param {void}
   * @returns {Promise<void>}
   */
  public async handleAppInitialization(): Promise<void> {
    const userDetails: Partial<IUserDetails> | null = await this.storageService.getLocalItem(STORAGE_KEYS.userDetails);
    return this.userAuthChanged(userDetails);
  }

  /**
   * @async
   * @function isTokenExpired
   * @description Checks if the token is expired. Returns true if expired, false otherwise.
   * @returns {Promise<boolean>}
   */
  public async isTokenExpired(): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        const userDetails: Partial<IUserDetails> | null = this.user$?.value;

        // If userDetails, auth, token, or tokenExp is missing, consider token as expired
        if (!userDetails?.auth?.token || !userDetails?.auth?.tokenExpDateIso) {
          return resolve(true);
        }

        // Extract token expiration time from userDetails
        const tokenExpTimestamp: number = new Date(userDetails.auth.tokenExpDateIso).getTime();
        // Check if the token expiration time is in seconds or milliseconds
        const isTimestampInSeconds: boolean = tokenExpTimestamp <= 2147483647;
        // Convert token expiration time to Date object
        const tokenDate: Date = isTimestampInSeconds ? new Date(tokenExpTimestamp * 1000) : new Date(tokenExpTimestamp);

        // Compare token expiration time with current time
        return resolve(isBefore(tokenDate, new Date()));
      } catch (error) {
        return resolve(true); // Consider token as expired if an error occurs
      }
    });
  }

  public async refreshUserDetails(): Promise<void> {
    this.apiService
      .getProfile()
      .then((profile: IProfile | null) => {
        this.userDetailsChanged(transformLoginDataToUserDetails({}, profile || {}));
      })
      .catch((error: unknown) => {
        console.log('Refreshing user data failed: ', error);
      });
  }

  public async loginWithEmailAndPassword(username: string, password: string, type: UserPersonaType): Promise<void> {
    try {
      let authResponse: ILoginPayload | null = null;

      if (type === 'player') {
        authResponse = await this.apiService.authenticatePlayerWithUsernameAndPassword(username, password);
      } else {
        authResponse = await this.apiService.authenticateAdminWithUsernameAndPassword(username, password);
      }

      if (!authResponse?.token) {
        throw new Error('Failed to login. Token is missing!');
      }

      await this.userAuthChanged(transformLoginDataToUserDetails(authResponse, {}));

      const userProfile: Partial<IProfile> | null = await this.apiService.getProfile();

      if (!userProfile?.id) {
        throw new Error('Failed to retreive user details!');
      }

      await this.userAuthChanged(transformLoginDataToUserDetails(authResponse, userProfile));
    } catch (error) {
      throw new Error('Failed to login. Please check your username or password or try again later!');
    }
  }

  public async logout(): Promise<void> {
    return this.userAuthChanged(null);
  }

  public async tryLoginWithExistingCredentials(userPersonaType: UserPersonaType): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        const existingCredentials: ILoginCredentials | null = await this.storageService.getLocalItem(SURF_POINT_STORAGE_KEYS.loginCredentials);
        await this.loginWithEmailAndPassword(existingCredentials?.username ?? EMPTY_STRING, existingCredentials?.password ?? EMPTY_STRING, userPersonaType);
        return resolve(true);
      } catch (error) {
        return reject(false);
      }
    });
  }

  public async refreshUserProfileData(): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const profile: IProfile | null = await this.apiService.getProfile();
        await this.userDetailsChanged(updateUserDetailsWithProfileData(this.user$.value || {}, profile || {}));
        return resolve();
      } catch (error) {
        return reject();
      }
    });
  }
  //#endregion utils
}
