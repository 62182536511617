import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule, ModalController } from '@ionic/angular';
import { DIALOG_DISMISS_ROLES } from 'bp-framework/dist/dialogs/dialogs.const';
import { IListItem } from 'bp-framework/dist/common/common.interface';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Subject, debounceTime } from 'rxjs';
import { FormsModule } from '@angular/forms';
import {
  filterListByKeyword,
  gatherSelectedItems,
  itemsFromSecondListShouldHaveSelectedStateSameAsItemsInTheFirstList,
  toggleSelectedStateOfAllItemsInTheList
} from './typeahead-select.utils';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'lib-typeahead-select',
  standalone: true,
  imports: [CommonModule, IonicModule, FormsModule, TranslateModule],
  templateUrl: './typeahead-select.component.html',
  styleUrls: ['./typeahead-select.component.scss']
})
export class TypeaheadSelectComponent implements OnInit {
  @Input() listOfItems: Partial<IListItem<any>>[] = [];
  @Input() multiselect = true;
  @Input() modalTitle = 'Select';

  private destroyRef: DestroyRef = inject(DestroyRef);

  filteredItems: Partial<IListItem<any>>[] = [];
  keyword$: Subject<string> = new Subject();
  selectDeselectT9nKey!: string;

  selectedItems: Partial<IListItem<any>>[] = [];

  constructor(private modalController: ModalController) {}

  //#region lifecycle hooks
  ngOnInit(): void {
    this.initialize();
  }
  //#endregion

  //#region methods
  private initialize(): void {
    this.selectedItems = this.listOfItems.filter((item: Partial<IListItem<any>>) => item.selected);
    this.filterListByKeyword('');
    this.keyword$.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(200)).subscribe((keyword: string) => this.filterListByKeyword(keyword));
  }
  //#endregion methods

  //#region events
  public handleSearchbarInputChange(event: Event) {
    this.keyword$.next((event as CustomEvent)?.detail?.value);
  }

  public handleCheckboxChange(event: Event, selectedItem: Partial<IListItem<any>>): void {
    selectedItem.selected = (event as CustomEvent).detail.checked;

    itemsFromSecondListShouldHaveSelectedStateSameAsItemsInTheFirstList(this.listOfItems, this.filteredItems);
    this.updateSelectDeselectLabel();
  }

  public handleRadioChange(event: Event): void {
    const value: any = (event as CustomEvent)?.detail?.value;
    const selectedItem: Partial<IListItem<any>> | undefined = this.filteredItems.find((item: Partial<IListItem<any>>) => item.value === value);

    if (selectedItem) {
      this.selectedItems = [selectedItem];
    }
  }

  public handleSelectDeselectAll(): void {
    toggleSelectedStateOfAllItemsInTheList(this.filteredItems);
    this.updateSelectDeselectLabel();
  }

  public handleClose(): void {
    this.modalController.dismiss(null, DIALOG_DISMISS_ROLES.cancel);
  }

  public handleConfirm(): void {
    let selectedItems: Partial<IListItem<any>>[] = [];

    if (this.multiselect) {
      selectedItems = gatherSelectedItems(this.listOfItems);
    } else if (!this.multiselect && this.selectedItems) {
      selectedItems = this.selectedItems;
    }

    this.modalController.dismiss(selectedItems, DIALOG_DISMISS_ROLES.confirm);
  }
  //#endregion events

  //#region utils
  private filterListByKeyword(keyword: string): void {
    this.filteredItems = filterListByKeyword(keyword, this.listOfItems);
    this.updateSelectDeselectLabel();
  }

  private updateSelectDeselectLabel(): void {
    const allSelected = this.filteredItems.every(item => item.selected);
    this.selectDeselectT9nKey = allSelected ? 'commons.deselectAll' : 'commons.selectAll';
  }
  //#endregion utils
}
