<ion-list [inset]="true">
  <ion-radio-group [value]="selectedCountry" (ionChange)="handleCountrySelected($event)">
    <ng-container *ngFor="let country of filteredListOfCountries">
      <ion-item>
        <lib-flag-img slot="start" [countryIsoAlpha2Code]="country.alpha2"></lib-flag-img>
        <ion-radio [value]="country" justify="space-between">{{ country?.name }}</ion-radio>
      </ion-item>
    </ng-container>
  </ion-radio-group>
</ion-list>
