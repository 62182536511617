<form *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="handleLogin()">
  <ion-list>
    <ion-item>
      <ion-input type="text" inputmode="text" [placeholder]="'commons.firstName' | translate" formControlName="firstName"></ion-input>
    </ion-item>
    <ion-item>
      <ion-input type="text" inputmode="text" [placeholder]="'commons.lastName' | translate" formControlName="lastName"></ion-input>
    </ion-item>
    <ion-item>
      <ion-input type="text" inputmode="text" [placeholder]="'commons.username' | translate" formControlName="username"></ion-input>
    </ion-item>
    <ion-item>
      <ion-input type="text" inputmode="text" [placeholder]="'commons.email' | translate" formControlName="email"></ion-input>
    </ion-item>
    <ion-item class="password-item">
      <ion-input [type]="passwordInputMode" inputmode="text" [placeholder]="'commons.password' | translate" formControlName="password"> </ion-input>
      <ion-button fill="clear" slot="end" [attr.aria-label]="'commons.showHide' | translate" (click)="togglePasswordInputMode('password')">
        <span slot="icon-only" aria-hidden="true" class="ph" [ngClass]="passwordInputMode === 'text' ? 'ph-eye-slash' : 'ph-eye'"></span>
      </ion-button>
    </ion-item>
    <ion-item class="password-item">
      <ion-input [type]="passwordConfirmationInputMode" inputmode="text" [placeholder]="'commons.passwordConfirmation' | translate" formControlName="passwordConfirmation">
      </ion-input>
      <ion-button fill="clear" slot="end" [attr.aria-label]="'commons.showHide' | translate" (click)="togglePasswordInputMode('passwordConfirmation')">
        <span slot="icon-only" aria-hidden="true" class="ph" [ngClass]="passwordConfirmationInputMode === 'text' ? 'ph-eye-slash' : 'ph-eye'"></span>
      </ion-button>
    </ion-item>
    <ion-item>
      <ion-checkbox justify="start" labelPlacement="end">I agree to the terms and conditions</ion-checkbox>
    </ion-item>
  </ion-list>
  <ion-button type="submit" [color]="submitBtnColor" [disabled]="formGroup.invalid" expand="block" class="ion-margin-top"
    ><ng-content select="[submitButtonContent]"></ng-content
  ></ion-button>
</form>
