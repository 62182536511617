import { IListGroup, IListItem } from 'bp-framework/dist/common/common.interface';

/**
 * Toggles the selected state of all items in the provided list of filtered item groups.
 * @param {IListGroup<IListItem<any>>[]} filteredListGroups - The list of filtered item groups.
 * @returns {void}
 */
export const toggleSelectedStateOfAllItemsInTheList = (filteredListGroups: Partial<IListItem<any>>[]): void => {
  const allSelected = filteredListGroups.every(item => item.selected);
  const newState = !allSelected;
  for (const item of filteredListGroups) {
    item.selected = newState;
  }
};

/**
 * Updates the selected state of items in the second list to match the selected state of corresponding items in the first list.
 * @param {IListGroup<IListItem<any>>[]} groups - The list of groups containing items whose selected state will be used as reference.
 * @param {IListGroup<IListItem<any>>[]} filteredListGroups - The list of groups containing items whose selected state will be updated.
 * @returns {void}
 */
export const itemsFromSecondGroupListShouldHaveSelectedStateSameAsItemsInTheFirstList = (
  groups: IListGroup<IListItem<any>>[],
  filteredListGroups: IListGroup<IListItem<any>>[]
): void => {
  for (const group of groups) {
    for (const item of group.items) {
      // Find the corresponding item in the filtered list
      const correspondingItem: IListItem<any> | undefined = filteredListGroups
        .find((filteredGroup: IListGroup<IListItem<any>>) => filteredGroup.id === group.id)
        ?.items.find((filteredItem: IListItem<any>) => filteredItem.label === item.label && filteredItem.value === item.value);

      // Update the selected state if a corresponding item is found
      if (correspondingItem) {
        correspondingItem.selected = item.selected;
      }
    }
  }
};

/**
 * Updates the selected state of items in the second list to match the selected state of corresponding items in the first list.
 * @param {IListGroup<IListItem<any>>[]} groups - The list of groups containing items whose selected state will be used as reference.
 * @param {IListGroup<IListItem<any>>[]} filteredListGroups - The list of groups containing items whose selected state will be updated.
 * @returns {void}
 */
export const itemsFromSecondListShouldHaveSelectedStateSameAsItemsInTheFirstList = (listOfItems: Partial<IListItem<any>>[], filteredItems: Partial<IListItem<any>>[]): void => {
  for (const item of listOfItems) {
    // Find the corresponding item in the filtered list
    const correspondingItem: Partial<IListItem<any>> | undefined = filteredItems.find(
      (filteredItem: Partial<IListItem<any>>) => filteredItem.label === item.label && filteredItem.value === item.value
    );
    // Update the selected state if a corresponding item is found
    if (correspondingItem) {
      correspondingItem.selected = item.selected;
    }
  }
};

/**
 * Filters the list of groups based on a keyword, returning groups containing items whose labels match the keyword.
 * @param {string} keyword - The keyword to filter by.
 * @param {IListGroup<IListItem<any>>[]} groups - The list of groups to filter.
 * @returns {IListGroup<IListItem<any>>[]} The filtered list of groups.
 */
export const filterGroupListByKeyword = (keyword: string, groups: IListGroup<IListItem<any>>[]): IListGroup<IListItem<any>>[] => {
  if (!Array.isArray(groups)) {
    return [];
  }
  // Convert the keyword to lowercase for case-insensitive matching
  const searchTerm: string = keyword.toLowerCase();
  // Filter the groups based on the keyword
  return groups
    .map((group: IListGroup<IListItem<any>>) => ({
      ...group,
      items: Array.isArray(group.items) ? group.items.filter((item: IListItem<any>) => item.label.toLowerCase().includes(searchTerm)) : []
    }))
    .filter((group: IListGroup<IListItem<any>>) => group.items.length > 0);
};

/**
 * Filters the list of groups based on a keyword, returning groups containing items whose labels match the keyword.
 * @param {string} keyword - The keyword to filter by.
 * @param {IListGroup<IListItem<any>>[]} groups - The list of groups to filter.
 * @returns {IListGroup<IListItem<any>>[]} The filtered list of groups.
 */
export const filterListByKeyword = (keyword: string, listOfItems: Partial<IListItem<any>>[]): Partial<IListItem<any>>[] => {
  if (!Array.isArray(listOfItems)) {
    return [];
  }
  // Convert the keyword to lowercase for case-insensitive matching
  const searchTerm: string = keyword.toLowerCase();
  // Filter the groups based on the keyword
  return listOfItems.filter((item: Partial<IListItem<any>>) => item?.label?.toLowerCase().includes(searchTerm));
};

/**
 * Gathers selected items from all groups for a multiselect operation.
 * @returns {IListItem<any>[]} The list of selected items.
 */
export const gatherSelectedItemsFromGroups = (groups: IListGroup<IListItem<any>>[]): IListItem<any>[] => {
  return groups
    ?.map((group: IListGroup<IListItem<any>>) => group.items)
    .reduce((accumulator: IListItem<any>[], current: IListItem<any>[]) => accumulator.concat(current), [])
    .filter((item: IListItem<any>) => item?.selected);
};

/**
 * Gathers selected items from all groups for a multiselect operation.
 * @returns {IListItem<any>[]} The list of selected items.
 */
export const gatherSelectedItems = (items: Partial<IListItem<any>>[]): Partial<IListItem<any>>[] => {
  return items.filter((item: Partial<IListItem<any>>) => item?.selected);
};
