import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { I18nService } from '../../services/i18n/i18n.service';
import { IonicModule } from '@ionic/angular';
import { FlagImgComponent } from '../flag-img/flag-img.component';

@Component({
  selector: 'lib-language-button',
  standalone: true,
  imports: [CommonModule, IonicModule, TranslateModule, FlagImgComponent],
  templateUrl: './language-button.component.html',
  styleUrl: './language-button.component.scss'
})
export class LanguageButtonComponent {
  public i18nService: I18nService = inject(I18nService);
}
