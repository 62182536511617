import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { DatetimePresentation } from '@ionic/core';
import { FormsModule } from '@angular/forms';
import { subDays } from 'date-fns';

@Component({
  selector: 'lib-date-picker',
  standalone: true,
  imports: [CommonModule, IonicModule, FormsModule],
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent {
  @Output() startDateChanged: EventEmitter<string> = new EventEmitter();
  @Output() endDateChanged: EventEmitter<string> = new EventEmitter();

  @Input() set startDateIso(value: string) {
    this.firstDate = value;
    this.firstDateMin = value;
  }
  @Input() set endDateIso(value: string) {
    this.secondDate = value;
    this.secondDateMin = value;
  }
  @Input() showSecondDate!: boolean;

  presentationType: DatetimePresentation = 'date-time';
  preferWheel = false;

  firstDate = this.startDateIso ? new Date(this.startDateIso).toISOString() : subDays(new Date(), 30).toISOString();
  firstDateMin = this.startDateIso;
  firstDateMax = '2030-05-31T23:59:59';
  firstDateModalTitle = 'Select Date';

  secondDate = this.endDateIso ? new Date(this.endDateIso).toISOString() : new Date().toISOString();
  secondDateMin = this.endDateIso;
  secondDateMax = '2030-05-31T23:59:59';
  secondDateModalTitle = 'Select End Date';

  showDefaultButtons = true;
  showDefaultTitle = false;

  localeCode = 'en-US';
  firstDayOfWeek = 1;
  showTimeLabel = true;
  timeLabel = 'Tiempo';
  doneTextLabel = 'Confirm';

  public handleStartDateChange(event: Event): void {
    const dateValueIso: string = (event as CustomEvent)?.detail?.value;
    this.secondDateMin = dateValueIso;
    this.startDateChanged.emit(dateValueIso);
  }

  public handleEndDateChange(event: Event): void {
    this.endDateChanged.emit((event as CustomEvent)?.detail?.value);
  }
}
