import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ILoginPayload, IProfile } from 'bp-framework/dist/env-specific/1x2team/user/user.interface';
import { assembleRequestInAbstractWay } from '../../../../shared/services/api/api.service.utils';
import { firstValueFrom } from 'rxjs';
import { PROJECT_ENV_CONFIG_TOKEN } from '../../../../shared/configuration/configuration.const';
import { IEnvConfig } from 'bp-framework/dist/configuration/configuration.interface';
import { IEnvApiOn1x2Team } from 'bp-framework/dist/env-specific/1x2team/configuration/configuration.interface';

@Injectable({
  providedIn: 'root'
})
export class CoreApi1x2TeamService {
  private projectConfig: IEnvConfig<IEnvApiOn1x2Team> = inject<IEnvConfig<IEnvApiOn1x2Team>>(PROJECT_ENV_CONFIG_TOKEN);
  private officeBaseUrl = this.projectConfig?.api?.baseUrls?.office;

  constructor(private httpClient: HttpClient) {}

  //#region Authentication
  public authenticatePlayerWithUsernameAndPassword(username: string, password: string): Promise<ILoginPayload | null> {
    const fullPath = `${this.officeBaseUrl}/${this.projectConfig?.api?.coreAuthEndpoints.playerLogin}`;
    return firstValueFrom(
      assembleRequestInAbstractWay<ILoginPayload>(this.httpClient, 'POST', fullPath, {
        body: { username, password }
      })
    );
  }

  public authenticateAdminWithUsernameAndPassword(username: string, password: string): Promise<ILoginPayload | null> {
    const fullPath = `${this.officeBaseUrl}/${this.projectConfig?.api?.coreAuthEndpoints.adminLogin}`;
    return firstValueFrom(
      assembleRequestInAbstractWay<ILoginPayload>(this.httpClient, 'POST', fullPath, {
        body: { username, password }
      })
    );
  }

  public getProfile(): Promise<IProfile | null> {
    const fullPath = `${this.officeBaseUrl}/${this.projectConfig?.api?.coreAuthEndpoints.getProfile}`;
    return firstValueFrom(assembleRequestInAbstractWay<IProfile>(this.httpClient, 'GET', fullPath));
  }
  //#endregion Authentication
}
