<!-- Col -->
<div class="bp-flex-item">
  <!-- Navigation Buttons -->
  <div class="bp-navigation-controls">
    <ion-button (click)="handlePageChange(1)" [disabled]="currentPage === 1" title="First Page" shape="circle" size="xx-small" color="cta-tertiary">⟪</ion-button>
    <ion-button (click)="handlePageChange(currentPage - 1)" [disabled]="currentPage === 1" title="Previous Page" shape="circle" size="xx-small" color="cta-tertiary">‹</ion-button>

    <span class="bp-page-indicator"> {{ 'commons.pageXofY' | translate: { currentPage: currentPage, totalPages: totalPages } }} </span>

    <ion-button (click)="handlePageChange(currentPage + 1)" [disabled]="currentPage === totalPages" title="Next Page" shape="circle" size="xx-small" color="cta-tertiary"
      >›</ion-button
    >
    <ion-button (click)="handlePageChange(totalPages)" [disabled]="currentPage === totalPages" title="Last Page" shape="circle" size="xx-small" color="cta-tertiary">⟫</ion-button>
  </div>
</div>
<!-- Col -->
<div class="bp-flex-item">
  <!-- Page Size Selector -->
  <div class="bp-page-size-selector">
    <label for="pageSize" class="bp-size-label">{{ 'commons.pageSize' | translate }}:</label>
    <ion-select aria-label="page-size" placeholder="Select" [ngModel]="limit" (ngModelChange)="handlePageSizeChange($event)">
      <ion-select-option *ngFor="let size of pageSizes" [value]="size">{{ size }}</ion-select-option>
    </ion-select>
  </div>
  <!-- Items Range Display -->
  <div class="bp-range-display">{{ 'commons.pageRange' | translate: { rangeStart: rangeStart, rangeEnd: rangeEnd, total: total } }}</div>
</div>
