<!-- <ion-toggle labelPlacement="start" [checked]="darkModeActive" (ionChange)="handleModeChange($event)">{{ labelT9nKey | translate }}</ion-toggle> -->

<div
  class="toggle-wrapper"
  [ngClass]="darkModeActive ? 'dark' : 'light'"
  (click)="toggleMode()"
  tabindex="0"
  role="button"
  [attr.aria-pressed]="darkModeActive"
  (keydown)="toggleMode()">
  <span class="label">light</span>
  <div class="icon-circle"></div>
</div>
