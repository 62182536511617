<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button fill="clear" (click)="handleClose()">Cancel</ion-button>
    </ion-buttons>
    <ion-title>{{ modalTitle }}</ion-title>
    <ion-buttons slot="end">
      <ion-button fill="clear" color="primary" (click)="handleConfirm()">Confirm</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ng-container *ngIf="filteredItems!.length > 0">
    <!-- Multiselect -->
    <ng-container *ngIf="multiselect">
      <ion-list [inset]="true">
        <ion-item *ngFor="let item of filteredItems">
          <ion-checkbox justify="start" labelPlacement="end" [(ngModel)]="item.selected" (ionChange)="handleCheckboxChange($event, item)" aria-label="">{{
            item?.label
          }}</ion-checkbox>
        </ion-item>
      </ion-list>
    </ng-container>
    <!-- Singleselect -->
    <ng-container *ngIf="!multiselect">
      <ion-list [inset]="true">
        <ion-radio-group [value]="selectedItems[0]?.value" (ionChange)="handleRadioChange($event)">
          <ion-item *ngFor="let item of filteredItems">
            <!-- <ion-checkbox slot="start" [(ngModel)]="item.selected" (ionChange)="handleItemCheckChange($event, item)" aria-label="Toggle task completion"></ion-checkbox> -->
            <ion-radio [value]="item?.value" justify="start" labelPlacement="end">{{ item?.label }}</ion-radio>
          </ion-item>
        </ion-radio-group>
      </ion-list>
    </ng-container>
  </ng-container>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <ion-searchbar (ionInput)="handleSearchbarInputChange($event)"></ion-searchbar>
    <ion-buttons slot="end" *ngIf="multiselect">
      <ion-button color="primary" (click)="handleSelectDeselectAll()">{{ selectDeselectT9nKey | translate }}</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
