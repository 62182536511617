import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { assembleRequestInAbstractWay } from '../../../../shared/services/api/api.service.utils';
import { firstValueFrom } from 'rxjs';
import { PROJECT_ENV_CONFIG_TOKEN } from '../../../../shared/configuration/configuration.const';
import { IEnvConfig } from 'bp-framework/dist/configuration/configuration.interface';
import { uniqueId } from 'bp-framework/dist/common/common.utils';

import { IEnvApiOnBetPlatform } from 'bp-framework/dist/env-specific/betplatform/configuration/configuration.interface';

import { IBpPayload } from 'bp-framework/dist/env-specific/betplatform/api/api.interface';
import {
  IBpCasinoGame,
  IBpCasinoLaunchDetails,
  IBpCasinoSearchParams,
  IBpCasinoTag,
  IBpGamesWithTags,
  IBpJackpotDetailsWhenAgent,
  IBpJackpotItem
} from 'bp-framework/dist/env-specific/betplatform/casino/casino.interface';
import { CasinoTagType } from 'bp-framework/dist/casino/casino.interface';
import { TransactionType } from 'bp-framework/dist/transactions/transactions.interface';
import { IBpTransactionGroup } from 'bp-framework/dist/env-specific/betplatform/transactions/transaction-groups.interface';
import { IApiRequestParams } from 'bp-framework/dist/api/api.interface';
import { IBpTokenPayload, IBpUserProfile } from 'bp-framework/dist/env-specific/betplatform/user/user.interface';
import { IBpPlayerProfile, IBpReferredPlayer } from 'bp-framework/dist/env-specific/betplatform/player/player.interface';
import { IBpCryptoDepositInit, IBpTransaction, IBpTransactionInitiate } from 'bp-framework/dist/env-specific/betplatform/transactions/transactions.interface';
import { IBpAffiliateCode } from 'bp-framework/dist/env-specific/betplatform/affiliates/affiliates.interface';
import { IBpEventSubscriptionsPayload } from 'bp-framework/dist/env-specific/betplatform/subscriptions/subscriptions.interface';
import { USER_PERSONA_TOKEN } from '../../../../shared/configuration/configuration.const';
import { ProjectEnvConfig } from '../../../../shared/configuration/configuration.utils';

import { IBpGreekKenoRound, IBpKenoCouponSubmit, IBpKenoCoupon, IBpKenoCouponStatus } from 'bp-framework/dist/env-specific/betplatform/greek-keno/greek-keno.interface';

@Injectable({
  providedIn: 'root'
})
export class BetPlatformApiService {
  private projectConfig: IEnvConfig<IEnvApiOnBetPlatform> = inject<IEnvConfig<IEnvApiOnBetPlatform>>(PROJECT_ENV_CONFIG_TOKEN);
  private userPersonaConfig: ProjectEnvConfig = inject(USER_PERSONA_TOKEN);

  private httpClient: HttpClient = inject(HttpClient);
  private baseUrl = this.projectConfig?.api?.baseUrls?.default;

  //#region USER | Authentication
  public authenticateWithUsernameAndPassword(username: string, password: string): Promise<IBpPayload<IBpTokenPayload> | null> {
    const fullPath = `${this.baseUrl}/auth/login`;
    return firstValueFrom(
      assembleRequestInAbstractWay<IBpPayload<IBpTokenPayload>>(this.httpClient, 'POST', fullPath, {
        body: { username, password }
      })
    );
  }

  public changePassword(oldPassword: string, newPassword: string): Promise<IBpPayload<unknown> | null> {
    const fullPath = `${this.baseUrl}/auth/password`;
    return firstValueFrom(
      assembleRequestInAbstractWay<IBpPayload<unknown> | null>(this.httpClient, 'POST', fullPath, {
        body: { old_password: oldPassword, new_password: newPassword }
      })
    );
  }

  public refreshToken(refreshToken: string): Promise<IBpPayload<IBpTokenPayload> | null> {
    const fullPath = `${this.baseUrl}/auth/refresh`;
    return firstValueFrom(
      assembleRequestInAbstractWay<IBpPayload<IBpTokenPayload> | null>(this.httpClient, 'POST', fullPath, {
        body: { refresh_token: refreshToken }
      })
    );
  }
  //#endregion

  //#region USER | Profile
  public getUserProfile(): Promise<IBpPayload<Partial<IBpUserProfile>> | null> {
    const fullPath = `${this.baseUrl}/profile`;
    return firstValueFrom(assembleRequestInAbstractWay<IBpPayload<Partial<IBpUserProfile>> | null>(this.httpClient, 'GET', fullPath));
  }

  public patchUserLanguage(data: { language: string }): Promise<IBpPayload<Partial<IBpUserProfile>> | null> {
    const fullPath = `${this.baseUrl}/profile`;
    return firstValueFrom(
      assembleRequestInAbstractWay<IBpPayload<Partial<IBpUserProfile>> | null>(this.httpClient, 'PATCH', fullPath, {
        body: data
      })
    );
  }
  //#endregion

  //#region PLAYER | Authentication
  public playerRegisterWithUsernameAndPassword(username: string, password: string, phone: string, affiliateCode?: string): Promise<IBpPayload<IBpPlayerProfile> | null> {
    const fullPath = `${this.baseUrl}/player/register`;
    return firstValueFrom(
      assembleRequestInAbstractWay<IBpPayload<IBpPlayerProfile> | null>(this.httpClient, 'POST', fullPath, {
        body: { username, password, affiliate_code: affiliateCode || undefined, phone_number: phone }
      })
    );
  }
  //#endregion

  //#region PLAYER | Profile
  public getPlayerProfile(): Promise<IBpPayload<IBpPlayerProfile> | null> {
    const fullPath = `${this.baseUrl}/player/profile`;
    return firstValueFrom(assembleRequestInAbstractWay<IBpPayload<IBpPlayerProfile> | null>(this.httpClient, 'GET', fullPath));
  }
  //#endregion

  //#region PLAYER | Transactions
  public getPlayerTransactions(
    start_date_iso: string,
    end_date_iso: string,
    limit: number,
    offset: number,
    transaction_types: TransactionType[],
    transaction_group_ids: string[]
  ): Promise<IBpPayload<IBpTransaction[]> | null> {
    const fullPath = `${this.baseUrl}/player/finance/transactions`;
    const params = {
      start_date: start_date_iso,
      end_date: end_date_iso,
      limit,
      offset,
      transaction_types,
      transaction_group_ids
    };
    return firstValueFrom(assembleRequestInAbstractWay<IBpPayload<IBpTransaction[]> | null>(this.httpClient, 'GET', fullPath, { params }));
  }

  public cryptoDepositInitiate(): Promise<IBpPayload<IBpCryptoDepositInit> | null> {
    const fullPath = `${this.baseUrl}/player/deposit/crypto/initialize`;
    return firstValueFrom(assembleRequestInAbstractWay<IBpPayload<IBpCryptoDepositInit> | null>(this.httpClient, 'POST', fullPath));
  }

  // The purpose of initiate is to create a transaction request with appropriate status and details. Usually, this is called before submitting the transaction.
  public cryptoWithdrawInitiate(wallet_id: number, amount: number, crypto_wallet_address: string): Promise<IBpPayload<IBpTransactionInitiate> | null> {
    const fullPath = `${this.baseUrl}/player/withdraw/crypto/initialize`;
    return firstValueFrom(
      assembleRequestInAbstractWay<IBpPayload<IBpTransactionInitiate> | null>(this.httpClient, 'POST', fullPath, {
        body: { wallet_id, amount, crypto_wallet_address }
      })
    );
  }

  public cryptoWithdrawSubmit(request_id: string): Promise<IBpPayload<IBpTransaction> | null> {
    const fullPath = `${this.baseUrl}/player/withdraw/crypto/submit`;
    return firstValueFrom(
      assembleRequestInAbstractWay<IBpPayload<IBpTransaction> | null>(this.httpClient, 'POST', fullPath, {
        body: { request_id }
      })
    );
  }

  public cryptoWithdrawCancel(request_id: string): Promise<IBpPayload<IBpTransaction> | null> {
    const fullPath = `${this.baseUrl}/player/withdraw/crypto/cancel`;
    return firstValueFrom(
      assembleRequestInAbstractWay<IBpPayload<IBpTransaction> | null>(this.httpClient, 'POST', fullPath, {
        body: { request_id }
      })
    );
  }
  //#endregion

  //#region PLAYER | Referral
  public getListOfPlayersReferredByPlayer(params: IApiRequestParams): Promise<IBpPayload<IBpReferredPlayer[]> | null> {
    const fullPath = `${this.baseUrl}/player/affiliate-codes/players`;
    return firstValueFrom(assembleRequestInAbstractWay<IBpPayload<IBpReferredPlayer[]> | null>(this.httpClient, 'GET', fullPath, { params }));
  }

  public getListOfPlayersAffiliateCodes(params: IApiRequestParams): Promise<IBpPayload<IBpAffiliateCode[]> | null> {
    const fullPath = `${this.baseUrl}/player/affiliate-codes`;
    return firstValueFrom(assembleRequestInAbstractWay<IBpPayload<IBpAffiliateCode[]> | null>(this.httpClient, 'GET', fullPath, { params }));
  }
  //#endregion

  //#region PLAYER | Favorites
  public getFavoriteGames(params: IApiRequestParams): Promise<IBpPayload<number[]> | null> {
    const fullPath = `${this.baseUrl}/casino/games/favorites`;
    return firstValueFrom(assembleRequestInAbstractWay<IBpPayload<number[]> | null>(this.httpClient, 'GET', fullPath, { params }));
  }

  public getIdsOfFavoriteGames(params: IApiRequestParams): Promise<IBpPayload<number[]> | null> {
    const fullPath = `${this.baseUrl}/casino/games/favorites`;
    return firstValueFrom(assembleRequestInAbstractWay<IBpPayload<number[]> | null>(this.httpClient, 'GET', fullPath, { params }));
  }

  public addGameToFavorites(gameId: number): Promise<IBpPayload<unknown> | null> {
    const fullPath = `${this.baseUrl}/casino/games/${gameId}/favorite`;
    return firstValueFrom(assembleRequestInAbstractWay<IBpPayload<unknown> | null>(this.httpClient, 'POST', fullPath));
  }

  public removeGameFromFavorites(gameId: number): Promise<IBpPayload<unknown> | null> {
    const fullPath = `${this.baseUrl}/casino/games/${gameId}/favorite`;
    return firstValueFrom(assembleRequestInAbstractWay<IBpPayload<unknown> | null>(this.httpClient, 'DELETE', fullPath));
  }
  //#endregion

  //#region PLAYER | Keno
  public getKenoRounds(params?: { limit?: number }): Promise<IBpPayload<IBpGreekKenoRound[]> | null> {
    const fullPath = `${this.baseUrl}/keno/rounds`;
    return firstValueFrom(assembleRequestInAbstractWay<IBpPayload<IBpGreekKenoRound[]> | null>(this.httpClient, 'GET', fullPath, { params }));
  }

  public submitKenoCoupon(numbers: number[], stake: number): Promise<IBpPayload<IBpKenoCouponSubmit> | null> {
    const fullPath = `${this.baseUrl}/keno/coupons`;
    return firstValueFrom(assembleRequestInAbstractWay<IBpPayload<IBpKenoCouponSubmit> | null>(this.httpClient, 'POST', fullPath, { body: { numbers, stake } }));
  }

  public getKenoCoupons(params?: { status?: IBpKenoCouponStatus; limit?: number }): Promise<IBpPayload<IBpKenoCoupon[]> | null> {
    const fullPath = `${this.baseUrl}/keno/coupons`;
    return firstValueFrom(assembleRequestInAbstractWay<IBpPayload<IBpKenoCoupon[]> | null>(this.httpClient, 'GET', fullPath, { params }));
  }
  //#endregion

  //#region ADMIN | Players
  public getPlayers(params: IApiRequestParams): Promise<IBpPayload<Partial<IBpPlayerProfile>[]> | null> {
    const fullPath = `${this.baseUrl}/${this.userPersonaConfig.userPersona}/players`;
    return firstValueFrom(assembleRequestInAbstractWay<IBpPayload<Partial<IBpPlayerProfile>[]>>(this.httpClient, 'GET', fullPath, { params }));
  }

  public getPlayer(playerId: string): Promise<IBpPayload<Partial<IBpPlayerProfile>> | null> {
    const fullPath = `${this.baseUrl}/${this.userPersonaConfig.userPersona}/players/${playerId}`;
    return firstValueFrom(assembleRequestInAbstractWay<IBpPayload<Partial<IBpPlayerProfile>> | null>(this.httpClient, 'GET', fullPath));
  }

  public addPlayer(username: string, password: string, language: string, phone_number: string): Promise<IBpPayload<Partial<IBpPlayerProfile>> | null> {
    const fullPath = `${this.baseUrl}/${this.userPersonaConfig.userPersona}/players`;
    return firstValueFrom(
      assembleRequestInAbstractWay<IBpPayload<Partial<IBpPlayerProfile>> | null>(this.httpClient, 'POST', fullPath, {
        body: { username, password, language, phone_number }
      })
    );
  }

  public makePlayerDeposit(playerId: string, amount: number): Promise<IBpPayload<unknown> | null> {
    const fullPath = `${this.baseUrl}/${this.userPersonaConfig.userPersona}/players/${playerId}/deposit`;
    const client_transaction_id: string = uniqueId();

    return firstValueFrom(
      assembleRequestInAbstractWay<IBpPayload<unknown> | null>(this.httpClient, 'POST', fullPath, {
        body: { amount, client_transaction_id }
      })
    );
  }

  public makePlayerWithdrawal(playerId: string, amount: number): Promise<IBpPayload<unknown> | null> {
    const fullPath = `${this.baseUrl}/${this.userPersonaConfig.userPersona}/players/${playerId}/withdraw`;
    const client_transaction_id: string = uniqueId();

    return firstValueFrom(
      assembleRequestInAbstractWay<IBpPayload<unknown> | null>(this.httpClient, 'POST', fullPath, {
        body: { amount, client_transaction_id }
      })
    );
  }

  public blockPlayer(playerId: string): Promise<IBpPayload<unknown> | null> {
    const fullPath = `${this.baseUrl}/${this.userPersonaConfig.userPersona}/players/${playerId}/block`;
    return firstValueFrom(assembleRequestInAbstractWay<IBpPayload<unknown> | null>(this.httpClient, 'POST', fullPath));
  }

  public unblockPlayer(playerId: string): Promise<IBpPayload<unknown> | null> {
    const fullPath = `${this.baseUrl}/${this.userPersonaConfig.userPersona}/players/${playerId}/unblock`;
    return firstValueFrom(assembleRequestInAbstractWay<IBpPayload<unknown> | null>(this.httpClient, 'POST', fullPath));
  }
  //#endregion

  //#region ADMIN | Transactions
  public adminGetTransactions(
    start_date_iso: string,
    end_date_iso: string,
    limit: number,
    offset: number,
    transaction_types: TransactionType[],
    transaction_group_ids: string[],
    user_ids: string[]
  ): Promise<IBpPayload<IBpTransaction[]> | null> {
    // date format: 2024-10-01T00:00:00Z
    const fullPath = `${this.baseUrl}/${this.userPersonaConfig.userPersona}/finance/transactions`;
    return firstValueFrom(
      assembleRequestInAbstractWay<IBpPayload<IBpTransaction[]> | null>(this.httpClient, 'GET', fullPath, {
        params: {
          start_date: start_date_iso,
          end_date: end_date_iso,
          limit,
          offset,
          transaction_types,
          transaction_group_ids,
          user_ids
        }
      })
    );
  }
  //#endregion

  //#region ADMIN | Jackpots
  public getJackpotsListForAgent(params: IApiRequestParams): Promise<IBpPayload<IBpJackpotDetailsWhenAgent[]> | null> {
    const fullPath = `${this.baseUrl}/${this.userPersonaConfig.userPersona}/jackpots`;

    return firstValueFrom(assembleRequestInAbstractWay<IBpPayload<IBpJackpotDetailsWhenAgent[]> | null>(this.httpClient, 'GET', fullPath, { params }));
  }

  public awardJackpot(playerId: string, jackpotId: number): Promise<IBpPayload<unknown> | null> {
    const fullPath = `${this.baseUrl}/${this.userPersonaConfig.userPersona}/jackpots/${jackpotId}/award`;
    return firstValueFrom(
      assembleRequestInAbstractWay<IBpPayload<unknown> | null>(this.httpClient, 'POST', fullPath, {
        body: { player_id: playerId }
      })
    );
  }
  //#endregion

  //#region CASINO | Games
  public getAllCasinoGames(params: IBpCasinoSearchParams): Promise<IBpPayload<IBpGamesWithTags> | null> {
    const fullPath = `${this.baseUrl}/casino/games`;
    return firstValueFrom(
      assembleRequestInAbstractWay<IBpPayload<IBpGamesWithTags> | null>(this.httpClient, 'GET', fullPath, {
        params
      })
    );
  }

  public getCasinoGameById(id: number): Promise<IBpPayload<IBpCasinoGame> | null> {
    const fullPath = `${this.baseUrl}/casino/games/${id}`;
    return firstValueFrom(assembleRequestInAbstractWay<IBpPayload<IBpCasinoGame> | null>(this.httpClient, 'GET', fullPath));
  }

  public getCasinoTags(params?: IBpCasinoSearchParams): Promise<IBpPayload<IBpCasinoTag[]> | null> {
    const fullPath = `${this.baseUrl}/casino/tags`;
    return firstValueFrom(assembleRequestInAbstractWay<IBpPayload<IBpCasinoTag[]> | null>(this.httpClient, 'GET', fullPath, { params }));
  }

  public getCasinoTypesOfTags(): Promise<IBpPayload<CasinoTagType[]> | null> {
    const fullPath = `${this.baseUrl}/casino/tags/types`;
    return firstValueFrom(assembleRequestInAbstractWay<IBpPayload<CasinoTagType[]> | null>(this.httpClient, 'GET', fullPath));
  }

  public getCasinoGamePlayUrl(gameId: number, mode: 'real' | 'demo'): Promise<IBpPayload<IBpCasinoLaunchDetails> | null> {
    const fullPath = `${this.baseUrl}/casino/games/${gameId}/launch/${mode}`;
    return firstValueFrom(assembleRequestInAbstractWay<IBpPayload<IBpCasinoLaunchDetails> | null>(this.httpClient, 'POST', fullPath));
  }
  //#endregion

  //#region CASINO | Jackpots
  public getJackpotsListForPlayers(params: IApiRequestParams): Promise<IBpPayload<IBpJackpotItem[]> | null> {
    const fullPath = `${this.baseUrl}/promo/jackpots`;
    return firstValueFrom(assembleRequestInAbstractWay<IBpPayload<IBpJackpotItem[]> | null>(this.httpClient, 'GET', fullPath, { params }));
  }
  //#endregion

  //#region BACKEND COMMUNICATION
  public subscribeToBackendEvents(): Promise<IBpPayload<IBpEventSubscriptionsPayload> | null> {
    const fullPath = `${this.baseUrl}/events/subscriptions`;
    return firstValueFrom(assembleRequestInAbstractWay<IBpPayload<IBpEventSubscriptionsPayload> | null>(this.httpClient, 'POST', fullPath));
  }
  //#endregion

  //#region FINANCE | Transactions
  public getFinanceTransactionGroups(): Promise<IBpPayload<IBpTransactionGroup[]> | null> {
    const fullPath = `${this.baseUrl}/finance/transaction_groups`;
    return firstValueFrom(assembleRequestInAbstractWay<IBpPayload<IBpTransactionGroup[]> | null>(this.httpClient, 'GET', fullPath));
  }
  //#endregion
}
