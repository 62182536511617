import { Component, OnInit, inject } from '@angular/core';
import { IonicModule, ToggleCustomEvent } from '@ionic/angular';
import { StorageService } from '../../services/storage/storage.service';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'lib-theme-toggle',
  standalone: true,
  imports: [CommonModule, IonicModule, TranslateModule],
  templateUrl: './theme-toggle.component.html',
  styleUrls: ['./theme-toggle.component.scss']
})
export class ThemeToggleComponent implements OnInit {
  private storageService: StorageService = inject(StorageService);
  public darkModeActive = false;
  public labelT9nKey = '';

  ngOnInit(): void {
    this.initialSetup();
  }

  //#region events
  public handleModeChange(event: Event): void {
    this.changeMode((event as ToggleCustomEvent).detail.checked);
  }

  public toggleMode(): void {
    this.darkModeActive = !this.darkModeActive;
    this.changeMode(this.darkModeActive);
  }
  //#endregion

  //#region methods
  private async initialSetup(): Promise<void> {
    // TODO: Temporary, until we get the full design for the 'light' mode, we will always default to 'dark' mode
    this.changeMode(true);
    return;

    const darkModeStateFromStorage: boolean | undefined = await this.storageService.getLocalItem('theme');

    if (typeof darkModeStateFromStorage === 'boolean') {
      this.changeMode(darkModeStateFromStorage);
    } else {
      const systemPreference: boolean = window?.matchMedia('(prefers-color-scheme: dark)')?.matches; // Use matchMedia to check the user preference
      this.changeMode(systemPreference);
    }
  }

  private changeMode(nextStateShouldBeDark: boolean | undefined): void {
    if (nextStateShouldBeDark === undefined) {
      return;
    }

    document.body.classList.toggle('dark', nextStateShouldBeDark);
    this.darkModeActive = nextStateShouldBeDark;
    this.storageService.setLocalItem('theme', this.darkModeActive);
    this.labelT9nKey = this.darkModeActive ? 'commons.darkMode' : 'commons.lightMode';
  }
  //#endregion
}
