import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule, ToastOptions } from '@ionic/angular';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { UserPersonaType } from 'bp-framework/dist/auth/auth.interface';
import { AuthService } from '../../../services/auth/auth.service';
import { DialogsService } from '../../../services/dialogs/dialogs.service';
import { EMPTY_STRING } from 'bp-framework/dist/common/common.const';

@Component({
  selector: 'lib-signup-section',
  standalone: true,
  imports: [CommonModule, IonicModule, FormsModule, ReactiveFormsModule, TranslateModule],
  templateUrl: './signup-section.component.html',
  styleUrls: ['./signup-section.component.scss']
})
export class SignupSectionComponent implements OnInit {
  @Input() customSuccessFunc!: () => void;
  @Input() customErrorFunc!: (error: Error) => void;
  @Input() submitBtnColor = 'success';
  @Input() userPersonaType: UserPersonaType = 'player';

  public formGroup!: FormGroup<{
    firstName: FormControl<string | null>;
    lastName: FormControl<string | null>;
    username: FormControl<string | null>;
    email: FormControl<string | null>;
    password: FormControl<string | null>;
    passwordConfirmation: FormControl<string | null>;
    agreeToTerms: FormControl<boolean | null>;
  }>;

  private formIsSubmitted!: boolean;

  public passwordInputMode: 'text' | 'password' = 'password';
  public passwordConfirmationInputMode: 'text' | 'password' = 'password';

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private dialogsService: DialogsService
  ) {}

  //#region lifecycle hooks
  ngOnInit(): void {
    this.initializeForm();
  }
  //#endregion

  //#region methods
  private initializeForm(): void {
    this.formGroup = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      username: ['', [Validators.required]],
      email: ['', [Validators.required]],
      password: ['', [Validators.required]],
      passwordConfirmation: ['', [Validators.required]],
      agreeToTerms: [false, [Validators.required]]
    });
  }

  public async handleLogin(): Promise<void> {
    if (!this.formGroup.valid || this.formIsSubmitted) {
      return;
    }

    this.formIsSubmitted = true;

    // const sitename: string = await firstValueFrom(this.routeManagementService.sitename$);
    const username: string = this.formGroup.controls.username.value || EMPTY_STRING;
    const password: string = this.formGroup.controls.password.value || EMPTY_STRING;

    await this.dialogsService.presentLoading();

    setTimeout(() => {
      this.formIsSubmitted = false;
      this.dialogsService.dismissLoading();
    }, 2000);
  }

  public handleSuccess(): void {
    if (typeof this.customSuccessFunc === 'function') {
      this.customSuccessFunc();
      return;
    }
  }

  public handleError(error: Error): void {
    if (typeof this.customErrorFunc === 'function') {
      this.customErrorFunc(error);
      return;
    }

    const options: ToastOptions = {
      message: error?.message,
      color: 'danger'
    };
    this.dialogsService.presentToast(options);
  }

  public togglePasswordInputMode(element: 'password' | 'passwordConfirmation'): void {
    switch (element) {
      case 'password':
        this.passwordInputMode = this.passwordInputMode === 'password' ? 'text' : 'password';
        break;
      case 'passwordConfirmation':
        this.passwordConfirmationInputMode = this.passwordConfirmationInputMode === 'password' ? 'text' : 'password';
        break;
    }
  }
  //#endregion
}
