import { AfterViewInit, DestroyRef, Directive, ElementRef, EventEmitter, Input, OnDestroy, Output, Renderer2, inject } from '@angular/core';
import { IonContent, Platform, ScrollDetail } from '@ionic/angular';
import { Platforms } from '@ionic/core';
import { BehaviorSubject, Subject, auditTime, debounceTime, switchMap, takeUntil, takeWhile, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({
  selector: '[dirMoveOnScroll]',
  standalone: true
})
export class MoveOnScrollDirective implements AfterViewInit, OnDestroy {
  @Input() ionContentRef!: unknown;
  @Input() moveDirection!: 'up' | 'down';
  @Input() elementsMovingOutsideWindow!: any[];
  @Output() elementHasMoved: EventEmitter<boolean> = new EventEmitter<boolean>();

  private destroyRef: DestroyRef = inject(DestroyRef);

  private desktopPlatform: Platforms = 'desktop';
  private cancelSubs$: Subject<boolean> = new Subject<boolean>();
  private elementIsAlreadyMoved!: boolean;

  private allowMovement$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private compId = Math.random().toString(36).substring(7);

  constructor(
    private hostElRef: ElementRef,
    private renderer: Renderer2,
    private platform: Platform
  ) {}

  //#region lifecycle hooks
  ngOnDestroy(): void {
    this.cancelSubs$.next(true);
    this.cancelSubs$.complete();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.initialize();
    }, 300);
  }
  //#endregion

  //#region methods
  private async initialize(): Promise<void> {
    const shouldWeImplementMovement: boolean = await this.isMovementPossible();

    if (!shouldWeImplementMovement) {
      return;
    }

    this.renderer.setStyle(this.hostElRef?.nativeElement, 'transition', `margin-${this.moveDirection === 'down' ? 'bottom' : 'top'} 0.3s ease-in-out`);

    this.subscribeToScrollEvent();
  }

  /**
   * @function isMovementPossible
   * @description Skup uslova koji ce odluciti da li je potrebno da se element pomera pomocu ove direktive
   * @returns {boolean} Vraca 'true' ili 'false'
   */
  private async isMovementPossible(): Promise<boolean> {
    if (!this.ionContentRef) {
      // Ukoliko ne postoji referenca ka IonContent elementu, onda nije moguce da se implementira ova direktiva
      return false;
    }

    if (!this.moveDirection) {
      // Ukoliko nije definisan pravac u kom se sklanja host element, onda nije moguce da se nastavi dalje
      return false;
    }

    if (this.platform.is(this.desktopPlatform)) {
      // Trenutno nam nije potrebno da ova direktiva ima efekta na 'desktop' platformama
      return false;
    }

    return true;

    const hostElementHeight: number = this.retreiveHostElementHeight();

    let heightOfOtherElements = 0;

    if (Array.isArray(this.elementsMovingOutsideWindow)) {
      /**
       * Nekada na istoj stranici mozemo imati vise elemenata koji koriste istu direktivu, npr header + footer
       * U tom slucaju, vazno je da izracunamo kolika je visina i tih ostalih elemenata koji se pomeraju
       */
      this.elementsMovingOutsideWindow?.forEach((item: any) => {
        let height = 0;

        if (item?.nativeElement?.offsetHeight) {
          height = item?.nativeElement?.offsetHeight;
        } else if (item?.el?.clientHeight) {
          height = item?.el?.clientHeight;
        }

        heightOfOtherElements = heightOfOtherElements + height;
      });
    }

    const heightOfAllElementsThatWillBeMoved: number = hostElementHeight + heightOfOtherElements;

    const ionContentScrollEl: HTMLElement = await (this.ionContentRef as IonContent).getScrollElement();
    const ionContentScrollableHeight: number = ionContentScrollEl?.scrollHeight;
    const ionContentOffsetHeight: number = ionContentScrollEl?.offsetHeight;

    /**
     * Poslednja provera koju radimo jeste da li je ukupna visina skrolabilnog sadrzaja veca od visine IonContent elementa + visine svih elemenata koji se pomeraju
     * U slucaju da je visina skrolabilnog sadrzaja manja od toga, onda nakon sto pomerimo elemente, skrolabilni sadrzaj ce izgubiti skrol i onda ne postoji nacin da se vrate elementi koji su pomereni
     */
    return ionContentScrollableHeight > heightOfAllElementsThatWillBeMoved + ionContentOffsetHeight;
  }

  private async subscribeToScrollEvent(): Promise<void> {
    if (!(this.ionContentRef instanceof IonContent)) {
      return;
    }

    this.allowMovement$
      .pipe(
        switchMap(allow => (allow && (this.ionContentRef as IonContent).ionScroll ? (this.ionContentRef as IonContent)?.ionScroll.pipe(auditTime(200)) : [])),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((value: CustomEvent<ScrollDetail>) => {
        this.moveElement(value?.detail);
      });
  }

  public toggleMovementAllow(state: boolean): void {
    this.allowMovement$.next(state);
  }

  public allowMovementWithDelay(): void {
    setTimeout(() => {
      this.allowMovement$.next(true);
    }, 400);
  }

  /**
   * @function moveElement
   * @description Funkcija koja ce pomeriti host element u jednom od dva moguca pravca (up | down)
   * @param {ScrollDetail} scrollDetails - Detalji koji su dobijeni iz svakog eventa koji je emitovan nakon skrola od strane IonContent
   * @returns {void}
   */
  private moveElement(scrollDetails: ScrollDetail): void {
    // console.log(`comp id: ${this.compId} - move element: ${this.allowMovement$.value}`);

    if (!this.allowMovement$.value) {
      return;
    }

    this.toggleMovementAllow(false);

    const hostElementHeight: number = this.retreiveHostElementHeight();
    const marginProp: string = this.moveDirection === 'up' ? 'marginTop' : 'marginBottom'; // Ako korisnik zeli da pomeri nesto na gore, onda cemo to uraditi pomocu 'marginTop' u suprotnom ide 'marginBottom'

    if (scrollDetails?.currentY <= 0 && scrollDetails?.startY <= 0) {
      // TODO: This code block will resolve issue on the iOS device. When te scrollY is on '0' position, user can pull down screen and that will trigger scroll events on IonContent. The issue was the fact that the user didn't scroll but pulled down view. This caused issue and pull down moved header to hidden.
      this.elementIsAlreadyMoved = false;
      this.renderer.setStyle(this.hostElRef?.nativeElement, marginProp, '0');
      this.elementHasMoved.emit(this.elementIsAlreadyMoved ? false : true);
      this.allowMovementWithDelay();
      return;
    }

    const scrollDirection: 'up' | 'down' = scrollDetails?.currentY > scrollDetails?.startY ? 'down' : 'up';

    if (this.elementIsAlreadyMoved && scrollDirection !== this.moveDirection) {
      // U slucaju da je ovaj element vec pomeren ili je pravac skrola suprotan od onog pravca gde je element pomere, onda ne moram nista da radimo
      // Ovaj deo, "scrollDirection !== this.moveDirection" radi sledece. Recimo da je header pomeren na gore (up), onda sve dok korisnik skroluje dole (down), mi ne zelimo nista da menjamo
      this.allowMovementWithDelay();
      return;
    }

    let marginValue = '';

    if (this.moveDirection === 'up') {
      marginValue = scrollDirection === this.moveDirection ? '0' : `-${hostElementHeight}px`; // Ukoliko se element pomera gore (up) i korisnik je poceo da skroluje na gore, onda to znaci da zapravo treba da se pozcija elemnta vrati na staro, tj da bude vidljiv. U suprotnom, element pomeramo u minus marginu (krijemo ga).
      this.elementIsAlreadyMoved = marginValue !== '0';
    } else if (this.moveDirection === 'down') {
      marginValue = scrollDirection !== this.moveDirection ? '0' : `-${hostElementHeight}px`; // Ukoliko se element pomera na dole (down) i korisnik je poceo da skroluje na gore, onda to znaci da zapravo treba da se pozcija elemnta vrati na staro, tj da bude vidljiv. U suprotnom, element pomeramo u minus marginu (krijemo ga).
      this.elementIsAlreadyMoved = marginValue === '0';
    }

    this.elementHasMoved.emit(this.elementIsAlreadyMoved ? false : true);
    this.renderer.setStyle(this.hostElRef?.nativeElement, marginProp, marginValue);

    this.allowMovementWithDelay();
  }

  private retreiveHostElementHeight(): number {
    const element: HTMLElement = this.hostElRef?.nativeElement as HTMLElement;
    return element?.offsetHeight ?? 0;
  }
  //#endregion
}
