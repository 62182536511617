<ng-container *ngIf="showHeader">
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button fill="clear" (click)="handleClose()">{{ 'commons.cancel' | translate }}</ion-button>
      </ion-buttons>
      <ion-title>{{ 'commons.select' | translate }}</ion-title>
      <ion-buttons slot="end">
        <ion-button fill="clear" color="primary" (click)="handleConfirm()">{{ 'commons.confirm' | translate }}</ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
</ng-container>
<ion-content>
  <ion-list>
    <ion-radio-group [compareWith]="compareWith" [value]="selectedLangOption" (ionChange)="handleChange($event)">
      <ion-item *ngFor="let language of i18nService.supportedLanguages; trackBy: trackItems">
        <lib-flag-img class="is-flag" slot="start" [style.border-radius.%]="50" [countryIsoAlpha2Code]="convertLangAlpha2ToCountryAlpha2Code(language?.value)"></lib-flag-img>
        <ion-radio [value]="language" *ngIf="language.labelT9nKey">{{ language.labelT9nKey | translate }}</ion-radio>
      </ion-item>
    </ion-radio-group>
  </ion-list>
</ion-content>
